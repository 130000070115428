import React, { useEffect, useState, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { ReactComponent as HierarchyIcon } from "./assets/hierarchy.svg";

// =========================
// Material-UI Icons
// =========================
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import ReorderIcon from "@material-ui/icons/Reorder";
import AddAPhotoRoundedIcon from "@material-ui/icons/AddAPhotoRounded";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import { Delete,  ExpandMore, WarningOutlined } from "@material-ui/icons";
import { getURL } from "./utils/cf";
import RoomSharpIcon from "@material-ui/icons/RoomSharp";
import { getNameFromId } from "./utils/getNameFromId";
import "gsap/CSSPlugin";
import NameRenderer from "./components/NameRenderer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  name: {
    position: "relative",
  },
  hoverContainer: {
    position: "absolute",
    zIndex: 100,
    top: "100%",
    left: "0",
    display: "flex",
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  extraPad: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  devBox: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  attrSectionTitle: {
    // textAlign: "center",
    fontSize: 14,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  attrLine: {
    display: "flex",
    width: "100%",
    // justifyContent: "center",
    marginBottom: 4,
  },
  attrHead: {
    fontSize: 13,
    fontStyle: "italic",
  },

  subAttrHead: {
    fontSize: 12,
    fontStyle: "italic",
  },
  attrValue: {
    fontSize: 13,
    fontWeight: 400,
    margin: "0 4px",
  },
  subAttrValue: {
    fontSize: 12,
    fontWeight: 400,
    margin: "0 4px",
  },
  enhancedName: {
    // textAlign: "center",
    fontSize: 14,
    fontStyle: "italic",
    marginBottom: theme.spacing(1),
  }
}));

const ListContent = ({
  id,
  color,
  biopsy__type,
  anatomic__site,
  setAnatomicSite,
  hierarchy,
  transfer_to_parent,
  list__type,
  user__notes,
  setBiopsy__type,
  delete__content,
  setUser__notes,
  user__image,
  setUser__image,
  user__comment,
  setUser__comments,
  inputs,
  onInputChange,
  droppedPinId,
  content_id,
  onLocatePin,
  attrInfo,
  native_name,
  defined_name,
  sns,
  onOpenNameBuilder,
  ar
}) => {
  const uploadFile = useRef();
  const prevNote = useRef();
  const [popOverRef, setPopOverRef] = useState(null);
  const classes = useStyles();
  // const {direction,order} = useContext(ListContext).duplicateAnatomicSiteList.current[content_id].items[droppedPinId];

  // =========================
  // UseStates
  // =========================
  const [image, setImage] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState("");
  const [valueEdit, setValueEdit] = useState("");
  const [editType] = useState("");
  const [notes, setNotes] = useState("");
  const [comment, setComment] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  // =========================
  // UseEffect
  // =========================

  useEffect(() => {
    setNotes(user__notes);
  }, [user__notes]);

  useEffect(() => {
    setImage(user__image);
  }, [user__image]);
  useEffect(() => {
    setComment(user__comment);
  }, [user__comment]);

  useEffect(() => {
    setComment(user__comment);
  }, [user__comment]);

  useEffect(() => {
    setShowWarning(
      user__notes || (user__image && user__image.length) || user__comment
    );
  }, [user__notes, user__image, user__comment]);

  // =========================
  // Event Handlers
  // =========================

  // Change the Biopsy type
  const handleBiopsyChange = (event) => {
    const newBiopsy = event.target.value;
    setBiopsy__type(newBiopsy);
  };

  // Delete a list content
  const handleDeleteContent = () => {
    delete__content(id);
  };

  // Handles opening of dialog box for user notes
  const handleClickOpen = () => {
    prevNote.current = notes;
    setValue(notes);
    setOpen(true);
  };

  // Handles closing of dialog box for user notes
  const handleOk = () => {
    setNotes(value);
    setUser__notes(value);
    setOpen(false);
  };

  // Handles cancel button for user notes
  const handleCancel = () => {
    setNotes(prevNote.current);
    setUser__notes(prevNote.current);
    setOpen(false);
  };

  // Handles closing of dialog box for name edit
  const handleOkEdit = () => {
    setAnatomicSite({
      ...anatomic__site,
      [editType]: { ...anatomic__site[editType], text: valueEdit },
    });
    setOpenEdit(false);
  };

  // Handles cancel button for name edit
  const handleCancelEdit = () => {
    setOpenEdit(false);
  };

  // Get data url from file

  const captureImage = async (e) => {
    e.preventDefault();
    try {
      const url = await getURL(e.target.files[0]);
      if (url && image && image.length > 0) {
        console.log("I am Called");
        setImage([...image, { user_img: url, user_image_note: "" }]);
        setUser__image([...image, { user_img: url, user_image_note: "" }]);
      } else if (url) {
        console.log("I am Called too");
        setImage([{ user_img: url, user_image_note: "" }]);
        setUser__image([{ user_img: url, user_image_note: "" }]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRequiredInput = (input, index) => {
    switch (input.type) {
      case "text":
        return (
          <TextField
            onChange={(e) => onInputChange(e.target.value, index)}
            value={input.value}
            label={input.label}
          />
        );
      case "select":
        return (
          <TextField
            select
            onChange={(e) => onInputChange(e.target.value, index)}
            value={input.value}
            label={input.label}
          >
            {input.options.map((opt) => {
              return <MenuItem value={opt}>{opt}</MenuItem>;
            })}
          </TextField>
        );

      case "multiselect":
        return (
          <TextField
            select
            SelectProps={{
              multiple: true,
              value: input.value,
              onChange: (e) => onInputChange(e.target.value, index),
            }}
            label={input.label}
          >
            {input.options.map((opt) => {
              return <MenuItem value={opt}>{opt}</MenuItem>;
            })}
          </TextField>
        );
      default:
        return null;
    }
  };

  // handle addition of notes for images
  const handleImageNote = (e, i) => {
    var temp = {};
    var tempImage = [...image];

    temp.user_img = tempImage[i].user_img;
    temp.user_image_note = e.target.value;

    tempImage.splice(i, 1, temp);
    console.log(tempImage);
    setUser__image(tempImage);
    // setImage(tempImage);
  };

  // handles image and note delete
  const handleImageDelete = (i) => {
    var temp = [...image];
    temp.splice(i, 1);
    setImage(temp);
    console.log(temp);
    setUser__image(temp);
  };

  // get Part of Name

  return (
    <>
      <Popover
        open={popOverRef != null}
        anchorEl={popOverRef}
        onClose={() => setPopOverRef(null)}
      >
        <List component="nav" aria-label="secondary mailbox folder">
          {hierarchy.reverse().map((parentId) => {
            return parentId ? (
              <ListItem
                button
                key={parentId}
                onClick={(event) => {
                  transfer_to_parent(parentId);
                  setPopOverRef(null);
                }}
              >
                <ListItemText primary={getNameFromId(parentId).getFullName()} />
              </ListItem>
            ) : null;
          })}
          {/* {children.map((childId) => {
            return (
              <ListItem
                button
                key={childId}
                onClick={(event) => {
                  event.stopPropagation();
                  transfer_to_parent(childId);
                  setPopOverRef(null);
                }}
              >
                <ListItemText primary={getNameFromId(childId).getFullName()} />
              </ListItem>
            );
          })} */}
        </List>
      </Popover>

      <div className="listContent">
        <Accordion style={{ width: "100%" }}>
          <AccordionSummary>
            <div className="listContent__basicInfo">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={handleDeleteContent}>
                  <IndeterminateCheckBoxRoundedIcon />
                </IconButton>
                {showWarning && (
                  <WarningOutlined
                    style={{
                      color: "#FFC947",
                    }}
                  />
                )}
              </div>

              {list__type === "comments" || list__type === "diagnosis" ? (
                <span
                  style={{
                    background: color,
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      padding: "0px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      overflow: "unset",
                    }}
                  >
                    {id}
                  </span>
                </span>
              ) : (
                <span>
                  {list__type === "ordered"
                    ? `${String.fromCharCode(64 + id)}.`
                    : null}
                </span>
              )}

              <span>
                {
                  <div className="transfer-container">
                    <Tooltip title={"Transfer"}>
                      <IconButton
                        disabled={hierarchy.length === 0}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPopOverRef(e.currentTarget);
                        }}
                      >
                        <HierarchyIcon
                          style={{
                            width: 20,
                            height: 20,
                            fill: "currentColor",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              </span>

              
                
                <NameRenderer
                  {...defined_name}
                  auto_related_name={ar}
                  sns={sns}
                  onClickEdit={(e) => {
                    e.stopPropagation();
                    onOpenNameBuilder();
                  }}
                />
            
              {/* {direction && getDirection()} */}
              {/* </p> */}

              {/* <p
                style={{
                  textAlign: "right",
                  overflowWrap: "break-word",
                  fontWeight: "bold",
                }}
              >
                {anatomic__site.laterlity.length === 0 && (
                  <span>(+Laterality) </span>
                )}
                
              </p> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton className="item-handle-icon">
                  <ReorderIcon
                    style={{
                      cursor: "move",
                      color: "#707070",
                      marginLeft: "5px",
                    }}
                  />
                </IconButton>
                <RoomSharpIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onLocatePin(droppedPinId);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#707070",
                    marginLeft: "5px",
                  }}
                />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <>
                <Accordion
                  className="listContent__additionalMetaData"
                  style={{ backgroundColor: "#999", margin: "16px auto" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                      content: classes.summary,
                    }}
                  >
                    <div>More Information</div>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.summary + " " + classes.extraPad }}
                  >
                    <div>
                      <p className={classes.attrSectionTitle}>
                        Pin-Point Meta Data:
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>Pin X : </span>
                        <span className={classes.attrValue}>
                          {attrInfo.coords.x}
                        </span>
                        <span className={classes.attrHead}>Pin Y : </span>
                        <span className={classes.attrValue}>
                          {attrInfo.coords.y}
                        </span>
                        <span className={classes.attrHead}>Pin Z : </span>
                        <span className={classes.attrValue}>{"NULL"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Pin-Point Over Map Element :
                        </span>
                        <span className={classes.attrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Pin-Point Has Associated Distribution :
                        </span>
                        <span className={classes.attrValue}>{"FALSE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>Pin-Point ID :</span>
                        <span className={classes.attrValue}>{id}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>Test ID :</span>
                        <span className={classes.attrValue}>
                          {"test_" + id}
                        </span>
                      </p>

                      <br />

                      {/* Divider */}
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point is in list type :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point is in list subtype :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point is in list thrash :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point needs a printable label :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point associated procedures needs consent form :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point associated procedures has signed consent
                          form :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point associated procedures signed consent form is
                          for Procedure Name :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>

                      <br />

                      {/* Divider */}

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point list has order :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point order in parent list :
                        </span>
                        <span className={classes.subAttrValue}>{"1"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point order marker :
                        </span>
                        <span className={classes.subAttrValue}>{"A"}</span>
                      </p>

                      <br />

                      {/* Divider */}
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has photos :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point photos have tags :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point photo tags :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Count of Photos associated with Pin-Point :
                        </span>
                        <span className={classes.subAttrValue}>{"0"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has attachments :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point attachments have tags :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point attachments tags :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Count of attachment associated with tags :
                        </span>
                        <span className={classes.subAttrValue}>{"0"}</span>
                      </p>

                      <br />

                      {/* Divider */}
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has custom user input :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point photos custom selection 1 Description :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point photos custom selection 1 :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-Point has associated measurement :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has more than one associated measurement :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has associated measurement contained in
                          Custom Selection :
                        </span>
                        <span className={classes.subAttrValue}>{"1,2"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point associated measurement unit :
                        </span>
                        <span className={classes.subAttrValue}>{"cm"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has associated count :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point associated count :
                        </span>
                        <span className={classes.subAttrValue}>{"0"}</span>
                      </p>

                      {/* Divider  */}
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point auto pin description :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point has custom pin description :
                        </span>
                        <span className={classes.subAttrValue}>{"TRUE"}</span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point custom pin description :
                        </span>
                        <span className={classes.subAttrValue}>{""}</span>
                      </p>

                      <br />
                      {/* Divider */}
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          PIN-POINT DIAGNOSIS INFORMATION :
                        </span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis customized :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis Extensions :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis category :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis category :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point Associated Diagnosis extensions are
                          customized :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <br />
                      {/* Divider */}

                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>AUTORELATION :</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point shares same anatomic name as another
                          Pin-point :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          HMAPS for Pin-Sharing same name :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          EGP ID is same for Pins sharing same name :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point sharing same anatomic name are on same HMAP
                          :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point sharing same anatomic name are over EGP :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>EGP width :</span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          EGP Height :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          PinX of other same Pin Name :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          PinY of other same Pin Name :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          PinID of other same Pin Name :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point sharing same anatomic-name are over EGP-T :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          X-Difference of this Pin-point compared to other same
                          name pin-point :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          X-Sign of this Pin-point relative to same name
                          Pin-point :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Y-Difference of this Pin-point compared to other same
                          name pin-point :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Y-Sign of this Pin-point relative to same name
                          Pin-point :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <br />
                      {/* Divider */}
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          PIN-POINT PARENT LIST INFORMATION :
                        </span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent list is grouped :
                        </span>
                        <span className={classes.subAttrValue}>FALSE</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has photos :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has photos have tag :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has photo tags :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Count of Pin-point parent group photos :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has attachments :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has attachments have tag :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Pin-point parent groups has attachments tags :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.subAttrHead}>
                          Count of Pin-point parent group attachments :
                        </span>
                        <span className={classes.subAttrValue}></span>
                      </p>

                      {/* Divider */}
                      <p className={classes.attrSectionTitle}>
                        User Name Builder Preference:
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Auto Enhance Modifiers :
                        </span>
                        <span className={classes.attrValue}>{"TRUE"}</span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Pin-point map has heirarchy level under list :
                        </span>
                        <span className={classes.attrValue}>
                          {hierarchy.join(",")}
                        </span>
                      </p>

                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Selected heirarchy level to display under Pin-point :
                        </span>
                        <span className={classes.attrValue}>
                          {anatomic__site.getFullName()}
                        </span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          Laterity Position in Name Builder :
                        </span>
                        <span className={classes.attrValue}>{"Beginning"}</span>
                      </p>
                    </div>
                    <div>
                      <p className={classes.attrSectionTitle}>
                        Pin-Point Anatomic Site Data:
                      </p>
                      <p className={classes.enhancedName}>
                        <NameRenderer {...defined_name} auto_related_name={ar} sns={sns} noEdit />
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>
                          English Site Name :
                        </span>
                        <span className={classes.attrValue}>
                          {attrInfo.name}
                        </span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>AMID : </span>
                        <span className={classes.attrValue}>
                          {attrInfo.amid}
                        </span>
                        <span className={classes.attrHead}>Laterity : </span>
                        <span className={classes.attrValue}>
                          {attrInfo.side}
                        </span>
                        <span className={classes.attrHead}>
                          Hierarchy Level :
                        </span>
                        <span className={classes.attrValue}>
                          {attrInfo.level}
                        </span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>Prefix : </span>
                        <span className={classes.attrValue}>
                          {defined_name.prefix.length
                            ? defined_name.prefix.join(",")
                            : ""}
                        </span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>Suffix : </span>
                        <span className={classes.attrValue}>
                          {defined_name.suffix.length
                            ? defined_name.suffix.join(",")
                            : ""}
                        </span>
                      </p>
                      <p className={classes.attrLine}>
                        <span className={classes.attrHead}>EGZ : </span>
                        <span className={classes.attrValue}>
                          {attrInfo.egz.egzt > 0 ? "TRUE" : "FALSE"}
                        </span>
                      </p>
                      {attrInfo.egz.egzt > 0 && (
                        <div>
                          <p className={classes.attrLine}>
                            <span className={classes.attrHead}>EGZT : </span>
                            <span className={classes.attrValue}>
                              {attrInfo.egz.egzt}
                            </span>
                            <span className={classes.attrHead}>EGZX : </span>
                            <span className={classes.attrValue}>
                              {attrInfo.egz.egzx}
                            </span>
                            <span className={classes.attrHead}>EGZY : </span>
                            <span className={classes.attrValue}>
                              {attrInfo.egz.egzy}
                            </span>
                          </p>

                          <p className={classes.attrLine}>
                            <span className={classes.attrHead}>
                              Deviation X :
                            </span>
                            <span className={classes.attrValue}>
                              {Math.round(attrInfo.deviation.dev_x) + "%"}
                            </span>
                            <span className={classes.attrHead}>
                              Deviation Y :
                            </span>
                            <span className={classes.attrValue}>
                              {Math.round(attrInfo.deviation.dev_y) + "%"}
                            </span>
                          </p>

                          <p className={classes.attrLine}>
                            <span className={classes.attrHead}>
                              Enhance Mod X :
                            </span>
                            <span className={classes.attrValue}>
                              {attrInfo.deviation.enhanceModX}
                            </span>
                            <span className={classes.attrHead}>
                              Enhance Mod Y :
                            </span>
                            <span className={classes.attrValue}>
                              {attrInfo.deviation.enhanceModY}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div className="listContent__userData">
                  <div>
                    {(list__type === "comments" ||
                      list__type === "diagnosis") && (
                      <input
                        style={{ padding: "0.5vw" }}
                        type="text"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                          setUser__comments(e.target.value);
                        }}
                        placeholder="Add comments"
                      />
                    )}
                    <FormControl
                      variant="outlined"
                      className={`${classes.formControl} listContent__basicInfo__dropdown__container`}
                    >
                      <InputLabel htmlFor="Biopsy">Type</InputLabel>
                      <Select
                        native
                        value={biopsy__type}
                        onChange={handleBiopsyChange}
                        label="Biopsy"
                        className="listContent__basicInfo__dropdown"
                        inputProps={{
                          name: "biopsy",
                          id: "biopsy",
                        }}
                      >
                        {list__type === "ordered" ? (
                          <>
                            <option value="Shave-Biopsy">Shave Biopsy</option>
                            <option value="Shave-Removal">Shave Removal</option>
                            <option value="Punch-Biopsy">Punch Biopsy</option>
                            <option value="Punch-Excision">
                              Punch Excision
                            </option>
                            <option value="Excision">Excision</option>
                          </>
                        ) : list__type === "comments" ? (
                          <>
                            <option value="General-Comment">
                              General Comment
                            </option>
                            <option value="Cosmetic-Comment">
                              Cosmetic Comment
                            </option>
                            <option value="Diagnosis-Comment">
                              Diagnosis Comment
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="Cryo-AK">Cryo - AK</option>
                            <option value="Cryo-Wart">Cryo - Wart</option>
                            <option value="Cryo-ISK">Cryo - ISK</option>
                            <option value="Injection-Med">
                              Injection - Med
                            </option>
                            <option value="Diagnosis-Nevus">Nevus</option>
                            <option value="Diagnosis-Acne">Acne</option>
                            <option value="Diagnosis-Psoriasis">
                              Psoriasis
                            </option>
                            <option value="Diagnosis-Eczema">Eczema</option>
                            <option value="Skin-cancer">Skin Cancer</option>
                            <option value="Custom-Lookup">Custom Lookup</option>
                          </>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <IconButton
                      style={{ cursor: "pointer" }}
                      onClick={handleClickOpen}
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                    &ensp;
                    <span>{notes ? notes : "ADD NOTES"}</span>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={uploadFile}
                    style={{ display: "none" }}
                    capture="camera"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => captureImage(e)}
                  />
                  <div className="upload-container">
                    <IconButton onClick={() => uploadFile.current.click()}>
                      <AddAPhotoRoundedIcon />
                    </IconButton>
                  </div>
                </div>

                <div className="listContent__Images">
                  <div className="images-box">
                    {image &&
                      image.map((img, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            <div className="image-list-item">
                              <img
                                key={i}
                                className="uploaded-img"
                                src={img.user_img}
                                alt="img"
                              />
                              <IconButton
                                onClick={() => {
                                  handleImageDelete(i);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            <div style={{ width: "100%", minWidth: "140px" }}>
                              <TextField
                                className="image-list-textfield"
                                placeholder="notes"
                                autoComplete="off"
                                // autoFocus
                                margin="dense"
                                type="text"
                                onChange={(e) => handleImageNote(e, i)}
                                value={img.user_image_note}
                                fullWidth
                                onBlur={() => setUser__image(image)}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="listContent__additionalInputs">
                  {inputs &&
                    inputs.map((input, index) => {
                      return (
                        <FormControl key={index}>
                          {getRequiredInput(input, index)}
                        </FormControl>
                      );
                    })}
                </div>
                <Accordion
                  className="listContent__additionalMetaData"
                  style={{ backgroundColor: "#999" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    -- Show additional documentation options --
                  </AccordionSummary>
                  <AccordionDetails className="app__mainBody__list__body">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Cumque, optio itaque beatae id, nam laborum maxime amet illo
                    laboriosam veniam sed quasi dignissimos blanditiis porro
                    maiores deleniti modi ipsam eaque.
                  </AccordionDetails>
                </Accordion>
              </>
            </div>
          </AccordionDetails>
        </Accordion>

        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Notes about "
            {list__type === "ordered" && `${String.fromCharCode(64 + id)}. `}
            {list__type === "comments" && `${id}`}
            {list__type === "diagnosis" && `${id}`}
            {anatomic__site.getFullName()} - {biopsy__type} Biopsy"
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your notes below...
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleOk} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openEdit}
          onClose={handleCancelEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Edit {editType} "
            {list__type === "ordered" && `${String.fromCharCode(64 + id)}. `}
            {list__type === "comments" && `${id}`}
            {list__type === "diagnosis" && `${id}`}
            {anatomic__site.getFullName()}"
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your changes below...
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              value={valueEdit}
              onChange={(e) => setValueEdit(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEdit} color="primary">
              Cancel
            </Button>
            <Button onClick={handleOkEdit} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ListContent;
