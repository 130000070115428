import {
  Button,
  Checkbox,
  Grid,
  // FormControlLabel,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Help,
  RefreshOutlined,
  Reorder,
  Visibility,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { globalSNSInitialState } from "../../App";
import CustomizedDialogs from "../Dialog/Dialog";

export const LabelRenderer = ({
  help,
  label,
  style,
  pre,
  post,
  checked,
  isCheckboxVisible,
  onChange,
}) => {
  return (
    <span
      style={{
        display: "flex",
        gap: 5,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flexBasis: 80 }}>
        {isCheckboxVisible && (
          <Checkbox
            color={"primary"}
            icon={<VisibilityOffOutlined />}
            checkedIcon={<Visibility />}
            checked={checked}
            onChange={onChange}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "left",
          flex: 1,
          gap: 10,
        }}
      >
        <Tooltip title={help}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "2px",
            }}
          >
            <Help fontSize={"small"} />
          </span>
        </Tooltip>
        <span style={style}>
          {pre}
          {label}
          {post}
        </span>
      </div>

      <div>
        <IconButton
          className={"sorting-handle"}
          style={{ cursor: "move", padding: 6 }}
        >
          <Reorder />
        </IconButton>
      </div>
    </span>
  );
};
export const NameLineSNS = ({
  label,
  list = [],
  onListChange,
  onRestoreSNS,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",

          marginBottom: 8,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 6,
            marginBottom: 10,
          }}
        >
          <span style={{ flex: 1 }}>
            <Grid container>
              <Grid item xs={8}>
                <span>
                  Load language specific default for site name sequence:{" "}
                </span>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  label={"Language"}
                  defaultValue={"English"}
                  size={"small"}
                  color={"primary"}
                  variant={"outlined"}
                >
                  <MenuItem value={"English"}>English</MenuItem>
                  <MenuItem value={"Spanish"}>Spanish</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </span>
          <span>
            <Tooltip title={"Restore to defaults"}>
              <IconButton
                color={"primary"}
                onClick={onRestoreSNS}
                style={{ padding: 0 }}
              >
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
          </span>
        </div>
        <ReactSortable
          list={list}
          setList={(newState) => onListChange(newState)}
          animation={200}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            flexWrap: "wrap",
          }}
          delayOnTouchStart={true}
          delay={2}
          handle=".sorting-handle"
        >
          {list.map(
            (
              { id, label, pre, post, style, visible, help, alwaysSelected },
              index
            ) => {
              return (
                <LabelRenderer
                  key={id}
                  help={help}
                  pre={pre}
                  post={post}
                  label={label}
                  style={style}
                  checked={visible}
                  isCheckboxVisible={!alwaysSelected}
                  onChange={(e) => {
                    const newList = [...list];
                    newList[index] = {
                      ...newList[index],
                      visible: e.target.checked,
                    };
                    onListChange(newList);
                  }}
                />
              );
            }
          )}
        </ReactSortable>
      </div>
    </>
  );
};

export default function SNSConfiguration({
  sns = { orderList: [], autoEnhance: true, autoRelate: true },
  open = false,
  onClose = () => {},
  // onSave = (newSns = {}) => {},
  // onSaveGlobally = (newSns) => {},
}) {
  const [snsState, setSnsState] = useState(sns);
  // const [applyToAll, setApplyToAll] = useState(false);
  useEffect(() => {
    if (open) {
      if (sns) {
        setSnsState(sns);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect(() => {
  //   if(isSingleVisible){
  //     onSave(snsState);
  //   }else{
  //     onSaveGlobally(snsState);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [snsState]);

  const handleChange = (newValue, name) => {
    setSnsState({
      ...snsState,
      [name]: newValue,
    });
  };

  return (
    <CustomizedDialogs
      open={open}
      onClose={() => onClose(snsState, false)}
      title={"Site Naming Sequence Configuration"}
      body={
        <div
          style={{
            width: 400,
            marginBottom: 8,
          }}
        >
          <NameLineSNS
            list={snsState.orderList}
            onRestoreSNS={() => {
              setSnsState(globalSNSInitialState);
            }}
            // label={"Anatomic Site Name Order : "}
            onListChange={(newList) => handleChange(newList, "orderList")}
          />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() => onClose(snsState, true)}
              size="small"
              style={{ margin: "auto" }}
            >
              Apply to all (Including Custom Sequences)
            </Button>
          </div>
          {/* <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={applyToAll}
                onChange={(e) => setApplyToAll(e.target.checked)}
              />
            }
            label={
              "Apply to all the sites including those which has custom sequence"
            }
          /> */}
        </div>
      }
      footer={
        null
        // <div style={{ gap: 10, display: "flex" }}>
        //   <Button
        //     variant={"contained"}
        //     color={"primary"}
        //     onClick={() => {
        //       onSaveGlobally(snsState);
        //     }}
        //   >
        //     Apply To All Sites
        //   </Button>
        //   {isSingleVisible ? (
        //     <Button
        //       variant={"contained"}
        //       color={"primary"}
        //       onClick={() => {
        //         onSave(snsState);
        //       }}
        //     >
        //       SAVE
        //     </Button>
        //   ) : null}
        // </div>
      }
    />
  );
}
