import React from "react";
import SVGMapper from "./components/SVGMapper";

import { useState } from "react";
// const svgUrl = Test4;
// const svgUrl = "https://visualizer.anatomymapper.com/images/test/amaps.svg";
const svgUrl =
  "https://visualizer.anatomymapper.com/images/test/amaps-vFB1-09.svg";

const AnatomyMapperMain = React.forwardRef(
  ({ gender, hideOpposite, isOralVisible, setOralVisible }, ref) => {
    const [activePathId, setActivePathId] = useState(null);
    // const [isHierarchy, setIsHierarchy] = useState(true);
    const [innerLayers, setInnerLayers] = useState([]);

    const onFetchMetaData = (pathObj) => {
      setActivePathId(pathObj.pathId);
      setInnerLayers(pathObj.innerLayers);
    };

    return (
      <div>
        <SVGMapper
          ref={ref}
          svg={svgUrl}
          onFetchMetaData={onFetchMetaData}
          gender={gender}
          hideOpposite={hideOpposite}
          isOralVisible={isOralVisible}
          setOralVisible={setOralVisible}
        />

        <p>
          {activePathId}{" "}
          {innerLayers && innerLayers.map((layerId) => "||" + layerId)}
        </p>
      </div>
    );
  }
);

export default AnatomyMapperMain;
