export const getNameFromId = (id) => {
  if (typeof id === "string") {
    if (id.includes("._")) {
      const [laterlityPart, restId] = id.split("._");
      const laterlity = laterlityPart.endsWith("L")
        ? "Left"
        : laterlityPart.endsWith("R")
        ? "Right"
        : "";

      const [namePart, ...rest] = restId.split("--");
      const indexOfHl = namePart.indexOf("HL");
      let name = indexOfHl !== -1 ? namePart.substring(0, indexOfHl) : namePart;
      name = name.split("_").join(" ");

      const prefixList = [];
      const suffixList = [];
      const researchList = [];

      rest.forEach((entity) => {
        let [key, value] = entity.split(":");
        value = value.split("-")[0];

        // prefix
        if (
          ["data-pre1", "data-pre2", "data-pre3", "data-pre4"].some(
            (k) => k === key
          )
        ) {
          prefixList.push(value);
        }

        // suffix
        if (["data-post1", "data-post2", "data-post3"].some((k) => k === key)) {
          suffixList.push(value);
        }

        // research
        if (["data-egzt", "data-egzx", "data-egzy"].some((k) => k === key)) {
          researchList.push(value);
        }
      });

      const data = {
        laterlity: {
          length: laterlity ? 1 : 0,
          text: laterlity,
          type: "laterlity",
        },
        prefix: {
          length: prefixList.length,
          text: prefixList.join(" "),
          type: "prefix",
        },
        name: {
          length: name ? 1 : 0,
          text: name,
          type: "name",
        },
        suffix: {
          length: suffixList.length,
          text: suffixList.join(" "),
          type: "suffix",
        },
        research: {
          length: researchList.length,
          text: researchList.join(" "),
          type: "research",
        },
        getFullName: () => {
          return (
            laterlity +
            " " +
            prefixList.join(" ") +
            " " +
            name +
            " " +
            suffixList.join(" ")
          );
        },
      };
      return data;
    } else if (id === "white_space") {
      const data = {
        laterlity: {
          length: 0,
        },
        prefix: {
          length: 0,
        },
        name: {
          length:  0,
          text: "",
          type: "name",
        },
        suffix: {
          length: 0,
        },
        research: {
          length: 0,
        },
        getFullName: () => {
          return id;
        },
      };

      return data;
    } else {
      // console.log(id);
      const [notNamePart, namePart] = id.split("-");
      let name = ''
      if(namePart){
        const indexOfHl = namePart.indexOf("HL");
        name = indexOfHl !== -1 ? namePart.substring(0, indexOfHl) : namePart;
      }else{
        name = notNamePart
      }
      
      name = name.split("_").join(" ");
      const data = {
        laterlity: {
          length: 0,
        },
        prefix: {
          length: 0,
        },
        name: {
          length: name ? 1 : 0,
          text: name,
          type: "name",
        },
        suffix: {
          length: 0,
        },
        research: {
          length: 0,
        },
        getFullName: () => {
          return name;
        },
      };

      return data;
    }
  }
  return id;
};
