import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState, useRef, useContext } from "react";
import {  getDate } from "../../utils/cf";
import {List} from '../../App';
const useStyles = makeStyles((theme) => ({
  withDimensions: {
    width: "100%",
    height: "100%",
    "&>svg": {
      width: "100%",
      height: "100%",
      outlineStyle: "dashed",
      outlineWidth: "2px",
      outlineColor: "#999",
      overflow: "hidden",
    },
  },
  withoutDimensions: {
    width: 0,
    height: 0,
  },
}));

const getDateOfServiceNode = ()=>document.getElementById('DateOfService')||null;
const getPTInfoNode = ()=>document.getElementById('PtInfoString:')||null;


const setPatientInfoOnMap = (patientInfo)=>{
    const {gender,firstName,lastName,DOB} = patientInfo;
    const piT = `${firstName || lastName ? "Name : "+firstName+ " "+ lastName+ "&emsp;": ""} ${gender? "("+gender.toUpperCase()[0]+")": ""} ${DOB? "DOB: "+getDate(DOB): ""}`;
    const dos = `DOS: ${getDate(new Date())}`;
    const pitNode = getPTInfoNode()?.querySelector('text');
    const dosNode = getDateOfServiceNode()?.querySelector('text');
    // getDateOfServiceNode()?.querySelector('text')?.innerHTML = dos;
    // getPTInfoNode()?.querySelector('text').innerHTML = piT;
    if(pitNode){
      pitNode.innerHTML = piT;
    }
    if(dosNode){
      dosNode.innerHTML = dos;
    }
}

const stylePatientInfoNodesOnMap = ()=>{
  const pitNode = getPTInfoNode();
  const dosNode = getDateOfServiceNode();
  
  if(pitNode){
  
    pitNode.style.opacity = "100%";
  }
  if(dosNode){
    dosNode.style.opacity = "100%";

  }

}


function LoadSVG({ url, svgProps = {}, onLoadSVG }) {
  const classes = useStyles();

  const {patientInfo} = useContext(List)
  const [isSVGLoaded, setIsSVGLoaded] = useState(false);
  const containerRef = useRef();
  useEffect(() => {
    const myHeaders = new Headers({
      "Content-Type": "text/plain",
      // 'X-Custom-Header': 'hello world'
    });
    // if(isSVGLoaded) setIsSVGLoaded(false);
    fetch(url, {
      headers: myHeaders,
    })
      .then((resp) => {
        // console.log(resp)
        return resp.text();
      })
      .then((svg) => {
        containerRef.current.innerHTML = svg;
        

        // containerR.current.getElementById('CopyrightAnatomyMapper') &&containerRef.current.getElementById('CopyrightAnatomyMapper').style= 'visibility:hidden;pointer-events:none;';
        setIsSVGLoaded(true);
      })
      .catch((e) => {});
  }, [url]);

  useEffect(()=>{
    setPatientInfoOnMap(patientInfo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[patientInfo.firstName,patientInfo.lastName,patientInfo.gender,patientInfo.DOB]);

  

  useEffect(() => {
    if (isSVGLoaded) {
      stylePatientInfoNodesOnMap(); 
      setPatientInfoOnMap(patientInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSVGLoaded]);

  return [
    !isSVGLoaded && (
      <div
        style={{
          display: "inline-block",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <CircularProgress />
      </div>
    ),
    <object
      aria-labelledby={"SVG Map"}
      id={"loaded-svg-cont"}
      ref={containerRef}
      {...svgProps}
      // style={{width: '100%'}}
      className={
        isSVGLoaded ? classes.withDimensions : classes.withoutDimensions
      }
    ></object>,
  ];
}

export default LoadSVG;
