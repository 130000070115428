import React from "react";
import Typography from "@material-ui/core/Typography";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import Anogenital from "../../assets/svg/Zoom_Anogenital.svg";
import Axillae from "../../assets/svg/Zoom_Axillae.svg";
import Face from "../../assets/svg/Zoom_Face.svg";
import Feet from "../../assets/svg/Zoom_Feet.svg";
import Body from "../../assets/svg/Zoom_Full Body.svg";
import Hands from "../../assets/svg/Zoom_Hands.svg";
import Oral from "../../assets/svg/Zoom_Oral.svg";
import Scalp from "../../assets/svg/Zoom_Scalp.svg";

export default function QuickZoom({ setOpen, open,onClickQuickZoomTile }) {
  
  const content = [
    {
      title: "Head & Neck",
      image: Face,
      id: "G-Head_and_Neck",
    },
    
    {
      title: "Scalp",
      image: Scalp,
      id: "G-Scalp",
    },
    {
      title: "Full Body",
      image: Body,
      id: "G-Full_Body",
    },
    {
      title: "Anogenital",
      image: Anogenital,
      id: "anogenital"
    },
    {
      title: "Hands",
      image: Hands,
      id: "G-Hands",
    },
    {
      title: "Axillae",
      image: Axillae,
      id: "G-Axillae",
    },
    {
      title: "Feet",
      image: Feet,
      id: "G-Feet",
    },
    {
      title: "Oral",
      image: Oral,
      id: "G-Oral_Anatomy",
    }
  ];

  

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="term-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="term-dialog-title" onClose={() => setOpen(false)}>
        Quick Zoom
      </DialogTitle>
      <DialogContent id="content" dividers>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {content.map((val, ind) => {
            return (
              <div key={ind} style={{ flexBasis: "50%", cursor: "pointer" }} onClick={()=>onClickQuickZoomTile(val.id)}>
                <img src={val.image} alt="head" />
                <Typography style={{ textAlign: "center" }}>
                  {val.title}
                </Typography>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
