import { modifiers, laterality } from "./EGZZonesProcessed.json";
export const SNSItems = {
  LATERALITY: {
    label: "Laterality",
    id: "laterality",
    pre: "",
    post: "",
    style: {
      textDecoration: "underline",
      fontStyle: "italic",
      marginRight: 4,
    },
    isArray: true,
    isEditable: true,
    strict: true,
    max: 1,
    autoCompleteOptions: laterality,
    alwaysSelected: false,
    visible: true,
    help: "",
  },
  ENHANCE_MODIFIERS: {
    label: "Enhanced Modifiers",
    id: "enhance_modifier",
    pre: "(",
    post: ")",
    style: { marginRight: 4, fontStyle: "italic" },
    isArray: true,
    isEditable: true,
    strict: true,
    autoCompleteOptions: modifiers,
    alwaysSelected: false,
    visible: true,
    help: "",
  },
  PREFIXES: {
    label: "Prefixes",
    id: "prefix",
    pre: "",
    post: "",
    style: { marginRight: 4, fontStyle: "italic" },
    isArray: true,
    isEditable: true,
    strict: true,
    autoCompleteOptions: modifiers,
    alwaysSelected: false,
    visible: true,
    help: "",
  },
  ANATOMIC_SITE_NAME: {
    label: "Anatomic Site",
    id: "anatomic_site",
    style: { fontWeight: 900, marginRight: 4 },
    isArray: false,
    pre: "",
    post: "",
    isEditable: false,
    strict: true,
    autoCompleteOptions: [],
    alwaysSelected: true,
    visible: true,
    help: "",
  },
  SUFFIXES: {
    label: "Suffixes",
    id: "suffix",
    pre: "{",
    post: "}",
    style: { marginRight: 4 },
    isArray: true,
    isEditable: true,
    strict: true,
    autoCompleteOptions: modifiers,
    alwaysSelected: false,
    visible: true,
    help: "",
  },
  DESCRIPTION: {
    label: "Custom Description",
    id: "description",
    pre: "[",
    post: "]",
    style: { marginRight: 4 },
    isArray: false,
    isEditable: true,
    strict: false,
    autoCompleteOptions: [],
    alwaysSelected: false,
    visible: true,
    help: "",
  },
  AUTO_RELATED_NAME: {
    label: "Auto Related Same Name",
    id: "auto_related_name",
    pre: "<",
    post: ">",
    style: { marginRight: 4 },
    isArray: true,
    isEditable: false,
    strict: false,
    autoCompleteOptions: [],
    alwaysSelected: false,
    visible: true,
    help: "When 2 or more pins are dropped on the same diagram and share the exact same anatomic site name, an automatic description will be generated to differentiate the pins from each other",
  },
  DISTRIBUTION: {
    label: "Distribution",
    id: "distribution",
    pre: "||",
    post: "||",
    style: { marginRight: 4 },
    isArray: false,
    isEditable: false,
    strict: false,
    alwaysSelected: false,
    visible: true,
    help: "",
    autoCompleteOptions: [],
  },
  ICD_CODE: {
    label: "ICD Anatomic Site Codes",
    id: "icd_codes",
    pre: "{(",
    post: ")}",
    style: { marginRight: 4 },
    isArray: false,
    isEditable: false,
    strict: false,
    autoCompleteOptions: [],
    alwaysSelected: false,
    visible: true,
    help: "",
  },
};

export const URL = "https://anatomymapper.com/";

export const TERMS_URL = URL + "terms/";
