import { data as EGZZones } from "../assets/data-files/EGZZonesProcessed.json";
const genRandomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

let patientInfoLS = localStorage.getItem("patientInfo");
let encounterInfoLS = localStorage.getItem("encounterInfo");
let userSettingsLS = localStorage.getItem("userSettings");

const data = {
  patientInfo: patientInfoLS ? JSON.parse(patientInfoLS) : {},
  encounterInfo: encounterInfoLS ? JSON.parse(encounterInfoLS) : {},
  userSettings: userSettingsLS ? JSON.parse(userSettingsLS) : {},
};

const searchParams = new URLSearchParams(window.location.search);

const getValue = (name = "", objName, initialValue) => {
  return searchParams.get(name) || data[objName][name] || initialValue;
};
let patientInfo = {
  firstName: getValue("firstName", "patientInfo", ""),
  lastName: getValue("lastName", "patientInfo", ""),
  DOB: getValue("DOB", "patientInfo", null),
  gender: getValue("gender", "patientInfo", ""),
  MRN: getValue("MRN", "patientInfo", ""),
  physicianName: getValue("physicianName", "patientInfo", ""),
  additionalInfo: getValue("additionalInfo", "patientInfo", ""),
  hideOpposite: getValue("hideOpposite", "patientInfo", false),
  showOral: getValue("showOral", "patientInfo", false),
};

let encounterInfo = {
  notes: getValue("notes", "encounterInfo", ""),
  sessionID: getValue("sessionID", "encounterInfo", 0),
  IPaddress: getValue("IPaddress", "encounterInfo", ""),
  dateTime: getValue("dateTime", "encounterInfo", new Date()),
};

let userSettings = {
  userEmail: getValue("userEmail", "userSettings", ""),
  physicianName: getValue("physicianName", "userSettings", ""),
  assistantName: getValue("assistantName", "userSettings", ""),
  clinicName: getValue("clinicName", "userSettings", ""),
  clinicAddress: getValue("clinicAddress", "userSettings", ""),
  clinicPhone: getValue("clinicPhone", "userSettings", ""),
  clinicFax: getValue("clinicFax", "userSettings", ""),
  clinicSite: getValue("clinicSite", "userSettings", ""),
  clinicEmail: getValue("clinicEmail", "userSettings", ""),
  clinicInfo: getValue("clinicInfo", "userSettings", ""),
  clinicLogo: getValue("clinicLogo", "userSettings", ""),
  acceptLic: getValue("acceptLic", "userSettings", false),
  acceptStatement: getValue("acceptStatement", "userSettings", false),
};

const generateFormDataFromSearchQuery = () => {
  return {
    patientInfo,
    userSettings,
    encounterInfo,
  };
};

const getURL = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const MAPPING_CLASS_MARKER_RADIUS = {
  ordered: 10,
  comments: 20,
  diagnosis: 20,
  unordered: 10,
};

const getClassOfDroppedPin = (droppedPinId) => {
  const id = droppedPinId.split("__")[0];
  return id;
};

const getMarkerRadius = (classId = "") => {
  return MAPPING_CLASS_MARKER_RADIUS[classId];
};

const getDirection = (target = { x: 0, y: 0 }, source = { x: 0, y: 0 }) => {
  console.log(target, source);
  return {
    left: target.x < source.x ? "Posterior" : "Anterior",
    top: target.y < source.y ? "Superior" : "Inferior",
  };
};

function getPathDetailsFromLayerId(layerId) {
  let elementRect = document.getElementById(layerId).getBoundingClientRect();
  return {
    position: {
      x: elementRect.x,
      y: elementRect.y,
      cx: elementRect.x + elementRect.width / 2, // X,Y Coordinates of the centre have to be calculated
      cy: elementRect.y + elementRect.height / 2,
      w: elementRect.width,
      h: elementRect.height,
    },
  };
  // We now have the SVG coordinates of the centre of the layer. We shall use this data in our component to calculate and display deviati
}

function getBounds(layerId) {
  let elementRect = document.getElementById(layerId).getBoundingClientRect();
  return {
    x: elementRect.x,
    y: elementRect.y,
    cx: elementRect.x + elementRect.width / 2, // X,Y Coordinates of the centre have to be calculated
    cy: elementRect.y + elementRect.height / 2,
    w: elementRect.width,
    h: elementRect.height,
  };
  // We now have the SVG coordinates of the centre of the layer. We shall use this data in our component to calculate and display deviati
}

const getDate = (DOB__obj) => {
  let birthDate = new Date(DOB__obj);

  if (Object.prototype.toString.call(birthDate) === "[object Date]") {
    // it is a date
    if (isNaN(birthDate.getTime())) {
      // date is not valid
      return "";
    } else {
      // date is valid
      return `${birthDate.getFullYear()}-${
        birthDate.getMonth() < 9
          ? `0${birthDate.getMonth() + 1}`
          : birthDate.getMonth() + 1
      }-${
        birthDate.getDate() < 10
          ? `0${birthDate.getDate()}`
          : birthDate.getDate()
      }`;
    }
  } else {
    // not a date
    return "";
  }
};

function getAllAttributes(layerId = "", deviation) {
  layerId = layerId.replace(/(-|#|_)(\d+)$/, "");
  let [namePart, ...dataParts] = layerId.split("--");
  let [amidSideInfo, nameInfo] = namePart.split("._");
  let [amid, side] = amidSideInfo.split("-");
  let name,
    hlLevel = "";
  if (nameInfo) {
    name = nameInfo.split("HL")[0];
    hlLevel = nameInfo.split("HL")[1];
  } else {
    name = namePart;
  }
  // let [name, hlLevel] = nameInfo.split("HL");
  dataParts = dataParts || [];
  const attributes = {
    egz: {
      egzt: null,
      egzx: null,
      egzy: null,
    },
    deviation: {
      ...deviation,
    },
    amid: Number(amid),
    side: side,
    name: name.replace(/_/g, " "),
    sns: null,
    level: "HL" + hlLevel,
    names: {
      laterality: [side === "L" ? "Left" : side === "R" ? "Right" : ""],
      prefix: [],
      suffix: [],
      enhance_modifier: [],
      description: "",
      distribution: "",
      auto_related_name: [],
      anatomic_site: name.replace(/_/g, " "),
      icd_codes: "",
      getFullName: function (sns, withoutAR = false) {
        const { orderList } = sns;
        let fullName = "";
        orderList.forEach(({ isArray, id, pre, post, visible }) => {
          if (visible) {
            if (id === "auto_related_name" && withoutAR) {
            } else {
              if (isArray) {
                if (this[id].length) {
                  fullName = fullName + " " + pre + this[id].join(" ") + post;
                }
              } else {
                if (this[id]) {
                  fullName = fullName + " " + pre + this[id] + post;
                }
              }
            }
          }
        });
        return fullName.trimLeft();
      },
    },
  };
  if (dataParts.length > 0) {
    const prefixes = [];
    const postfixes = [];
    const egz = {};
    dataParts.forEach((datum) => {
      let [key, value] = datum.split(":");
      key = key.replace("data-", "");
      if (key.startsWith("pre")) {
        prefixes.push(value);
      } else if (key.startsWith("post")) {
        postfixes.push(value);
      } else if (key.startsWith("egz")) {
        egz[key] = value;
      }
    });
    attributes["egz"] = {
      egzt: egz.egzt ? Number(egz.egzt) : defaults.egzt,
      egzx: egz.egzx ? Number(egz.egzx) : defaults.egzx,
      egzy: egz.egzy ? Number(egz.egzy) : defaults.egzy,
    };
    const { x, y } = getEnhanceModifier(attributes.egz, deviation);
    const namePrefixes = [];
    if (x && y) {
      if (Math.abs(deviation.dev_x) > Math.abs(deviation.dev_y) + 20) {
        namePrefixes.push(x);
        namePrefixes.push(y);
      } else {
        namePrefixes.push(y);
        namePrefixes.push(x);
      }
    } else {
      if (y) {
        namePrefixes.push(y);
      }
      if (x) {
        namePrefixes.push(x);
      }
    }

    attributes["names"] = {
      ...attributes["names"],
      prefix: prefixes,
      suffix: postfixes,
      enhance_modifier: namePrefixes,
    };

    attributes["deviation"] = {
      ...deviation,
      enhanceModX: x,
      enhanceModY: y,
    };

    return attributes;
  } else {
    return attributes;
  }
}

function getAnatomicName(layerId = "") {
  layerId = layerId.replace(/(-|#|_)(\d+)$/, "");
  let [namePart] = layerId.split("--");
  let [_, nameInfo] = namePart.split("._");
  let name = "";
  if (nameInfo) {
    name = nameInfo.split("HL")[0];
  } else {
    name = _;
  }
  return name.replace(/_/g, " ");
}

function getRootSVG() {
  return document.querySelector(`#loaded-svg-cont`)?.firstChild || null;
}

function getLabel(id) {
  return document.querySelector(`#${id} .Pin-Label`)?.innerHTML || "";
}
const defaults = {
  egzt: null,
  egzx: 50,
  egzy: 50,
};

function getEGZInfo(layerId, coords, without_zoomcoords) {
  const { dev_x, dev_y } = getDeviation(getBounds(layerId), coords);
  const attr = getAllAttributes(layerId, { dev_x, dev_y });
  attr["coords"] = without_zoomcoords;
  return attr;
}

function getEnhanceModifier(egz, deviation) {
  const { egzt, egzx, egzy } = egz;
  const targetEGZone = EGZZones[egzt];
  if (targetEGZone) {
    const isDeviationXAllowed = egzx < Math.abs(deviation.dev_x);
    const isDeviationYAllowed = egzy < Math.abs(deviation.dev_y);
    return {
      x: isDeviationXAllowed
        ? deviation.dev_x > 0
          ? targetEGZone["pos_x"]
          : targetEGZone["neg_x"]
        : null,
      y: isDeviationYAllowed
        ? deviation.dev_y > 0
          ? targetEGZone["pos_y"]
          : targetEGZone["neg_y"]
        : null,
    };
  } else {
    return {
      x: null,
      y: null,
    };
  }
}

function getDeviation(layoutBox, pointCoordinates) {
  return {
    dev_x: ((pointCoordinates.x - layoutBox.cx) * 100) / (layoutBox.w / 2),
    dev_y: ((pointCoordinates.y - layoutBox.cy) * 100 * -1) / (layoutBox.h / 2),
  };
}

function getCenter(domRect) {
  return {
    x: domRect.x + domRect.width / 2,
    y: domRect.y + domRect.height / 2,
  };
}

const getNewDuplicateContents = (duplicateList) => {
  let newDuplicateList = {};
  const list = Object.keys(duplicateList);
  if (list.length > 1) {
    const newList = list.map((droppedPinId, index) => {
      let newX = 0,
        newY = 0;
      // if(coords){
      //   const {x,y} = coords;
      //   newX = x; newY=y;
      // }else{
      const { x, y } = document
        .querySelector("#" + droppedPinId + ">g>.Pin-Marker")
        ?.getBoundingClientRect();
      newX = x;
      newY = y;
      // }
      return {
        newX,
        newY,
        droppedPinId,
      };
    });

    newList.sort((a, b) => a.newX - b.newX);
    newList.forEach(({ newX, newY, droppedPinId }, index) => {
      if (index === 0) {
        newDuplicateList[droppedPinId] = {
          order: index,
          direction: getDirection(
            { x: newX, y: newY },
            {
              x: newList[newList.length - 1].newX,
              y: newList[newList.length - 1].newY,
            }
          ),
          relativeOrder: newList.length - 1,
        };
      } else {
        newDuplicateList[droppedPinId] = {
          order: index,
          direction: getDirection(
            { x: newX, y: newY },
            { x: newList[index - 1].newX, y: newList[index - 1].newY }
          ),
          relativeOrder: index - 1,
        };
      }
    });
  } else if (list.length === 1) {
    newDuplicateList[list[0]] = {
      order: null,
      direction: null,
      relativeOrder: null,
    };
  }

  return {
    items: newDuplicateList,
    length: list.length,
  };
};

const copyAndChangeArray = (arr, id, newValue) => {
  return arr.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        ...newValue,
      };
    } else {
      return item;
    }
  });
};

const getDirectionInWords = (source, target) => {
  let y = "";
  let x = "";
  const devY = source.dev_y - target.dev_y;
  const devX = source.dev_x - target.dev_x;
  if (Math.abs(devY) <= 5) {
    y = null;
  } else {
    if (devY > 0) {
      y = "Inferior";
    } else {
      y = "Superior";
    }
  }

  if (Math.abs(devX) <= 5) {
    x = null;
  } else {
    if (devX > 0) {
      x = "Medial";
    } else {
      x = "Lateral";
    }
  }

  return { x, y };
};

export {
  genRandomString,
  generateFormDataFromSearchQuery,
  getURL,
  copyAndChangeArray,
  getRootSVG,
  getAnatomicName,
  // getDeviation,
  getCenter,
  getEGZInfo,
  getMarkerRadius,
  getClassOfDroppedPin,
  getNewDuplicateContents,
  getPathDetailsFromLayerId,
  getEnhanceModifier,
  getDate,
  getLabel,
  getDirectionInWords,
};
