const calculateAge = (dobString, formDateString) => {
  let encounterDate = formDateString ? new Date(formDateString) : new Date();
  let birthDate = new Date(dobString);
  var ageDifMs = encounterDate - birthDate.getTime();
  var ageDate = new Date(ageDifMs);
  const age = ageDate.getUTCFullYear() - 1970;
  return age <= 0 ? 0 : age;
};

export default calculateAge;
