import React from 'react'
import { Backdrop, Typography } from '@material-ui/core';

export default function BackdropWithLoader({ open}) {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div>

      <Typography variant="h5" component="h5" style={{ color: '#fff', textAlign: 'center' }}>
          Generating your pdf ......
      </Typography>

      <Typography variant="h6" component="h6" style={{ color: '#fff', textAlign: 'center' }}>
          Please wait for few seconds
      </Typography>
      </div>
    </Backdrop>
  )
}
