import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  Add,
  HelpOutlined,
  RefreshOutlined,
  Reorder,
  SettingsOutlined,
  Visibility,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import CustomizedDialogs from "../Dialog/Dialog";

import NameRenderer from "../NameRenderer";

export const LabelRenderer = ({ help, label, style, pre, post }) => {
  return help ? (
    <span style={{ display: "flex", gap: 5 }}>
      <span style={style}>
        {pre}
        {label}
        {post}
      </span>
      <Tooltip title={help}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: "2px",
          }}
        >
          <HelpOutlined fontSize={"small"} />
        </span>
      </Tooltip>
    </span>
  ) : (
    <span>
      <span style={style}>
        {pre}
        {label}
        {post}
      </span>
    </span>
  );
};

export const NameLine = ({
  pre,
  post,
  label,
  value,
  strict,
  onChange,
  isArray,
  isEditable,
  style,
  max = Infinity,
  autoCompleteOptions,
  help = null,
}) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [editIndex, setEditIndex] = useState(-1);

  const handleChange = (newValue) => {
    if (isArray) {
      onChange(newValue.map(({ label }) => label));
    } else {
      onChange(newValue);
    }
  };

  const handleInputChange = (newValue) => {
    if (isArray) {
      if (editIndex === -1) {
        handleChange([...value, { id: newValue, label: newValue }]);
      } else {
        const newArray = [...value];
        newArray[editIndex] = { id: newValue, label: newValue };
        handleChange(newArray);
      }
    } else {
      handleChange(newValue);
    }

    setInput("");
    setEditIndex(-1);
    setOpen(false);
  };
  return (
    <>
      <CustomizedDialogs
        open={open}
        onClose={() => setOpen(false)}
        title={label}
        body={
          <div style={{ width: 200 }}>
            {autoCompleteOptions.length !== 0 ? (
              <Autocomplete
                value={input}
                autoComplete={true}
                onInputChange={(_, value) => {
                  setInput(value);
                }}
                options={autoCompleteOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Type here..." />
                )}
              />
            ) : (
              <TextField
                label={"Type Here.."}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            )}
          </div>
        }
        footer={
          <div>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                if (!input) {
                  if (strict) {
                    alert("Please select any value from dropdown");
                  } else {
                    alert("Can't leave field blank");
                  }
                } else {
                  handleInputChange(input);
                }
              }}
            >
              {editIndex === -1 ? "ADD" : "SAVE"}
            </Button>
          </div>
        }
      />

      {!isArray && isEditable ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 4,
            marginBottom: 4,
            overflow: "auto",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: 48 }}>{pre}</span>
          <div style={{ flex: 1, padding: "10px 10px 0 10px" }}>
            <TextField
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              label={label}
              fullWidth
              size={"small"}
              // variant={"outlined"}
            />
          </div>
          <span style={{ fontSize: 48 }}>{post}</span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "0 0 240px",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 6,
            }}
          >
            <LabelRenderer
              help={help}
              label={label}
              style={style}
              pre={pre}
              post={post}
            />
            {isArray && isEditable && (
              <span>
                <IconButton
                  color={"primary"}
                  onClick={() => {
                    setInput("");
                    setOpen(true);
                  }}
                  disabled={value.length === max}
                  style={{ padding: 0 }}
                >
                  {" "}
                  <Add />{" "}
                </IconButton>
              </span>
            )}
            :
          </div>

          <div style={{ display: "flex", overflow: "auto" }}>
            <span style={{ marginLeft: 8, fontSize: 20, marginRight: 4 }}>
              {"  " + pre + " "}
            </span>
            {isArray && isEditable ? (
              <ReactSortable
                list={value}
                setList={(newValue) => handleChange(newValue)}
                animation={200}
                style={{
                  display: "flex",
                  gap: 8,
                  flex: 1,
                  overflow: "auto",
                  minWidth: 40,
                }}
                delayOnTouchStart={true}
                delay={2}
                handle=".sorting-handle"
              >
                {value.map(({ id, label }, index) => {
                  return (
                    <span
                      className={"sorting-handle"}
                      key={id}
                      onClick={() => {
                        setEditIndex(index);
                        setInput(label);
                        setOpen(true);
                      }}
                    >
                      <Chip
                        label={label}
                        onDelete={() => {
                          const newList = [...value];
                          newList.splice(index, 1);
                          handleChange(newList);
                          // onChange(value.map(({id,label})=>label))
                        }}
                      />
                    </span>
                  );
                })}
              </ReactSortable>
            ) : (
              <span
                style={{
                  // cursor: "pointer",
                  minWidth: 40,
                  ...style,
                }}
                onClick={() => {
                  console.log(value);

                  // setEditIndex(4);
                  // setOpen(true);
                  // setInput(value);
                }}
              >
                {isArray ? value.map(({ label }) => label).join(" ") : value}
              </span>
            )}
            <span style={{ fontSize: 20 }}>{post + " "}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default function NameBuilder({
  names,
  onClose,
  open,
  globalSNS,
  sns,
  onOpenSNS,
}) {
  const [namesState, setNamesState] = useState({
    laterality: [],
    prefix: [],
    suffix: [],
    enhance_modifier: [],
    anatomic_site: "",
    description: "",
  });

  const [snsState, setSnsState] = useState(sns);
  const isRestore = useRef(false);
  useEffect(() => {
    if (open) {
      setNamesState(names);
      setSnsState(sns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect(()=>{
  //   if(timeOutRef.current){
  //     clearTimeout(timeOutRef.current);
  //   }

  //   timeOutRef.current = setTimeout(()=>{
  //     onSave(namesState);
  //   },100);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[namesState]);

  const handleChange = (value, name) => {
    setNamesState({
      ...namesState,
      [name]: value,
    });
  };

  const onChangeVisibility = (index, newVisible) => {
    const newSnSState = { ...snsState };
    newSnSState.orderList = [...newSnSState.orderList];
    newSnSState.orderList[index] = {
      ...newSnSState.orderList[index],
      visible: newVisible,
    };
    setSnsState(newSnSState);
    isRestore.current = false;
  };

  return (
    <CustomizedDialogs
      open={open}
      onClose={() => onClose(namesState, snsState, isRestore.current)}
      title={"Name Builder"}
      body={
        <div style={{ minWidth: 450, maxWidth: 600 }}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              textOverflow: "ellipsis",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                background: "rgba(139,182,189,.2)",
                borderRadius: 4,
                padding: 8,
                border: "1px solid rgb(139,182,189)",
              }}
            >
              <span style={{ marginRight: 4, width: "9ch" }}> Preview :</span>
              <NameRenderer {...namesState} sns={snsState} noEdit />
            </div>
            <div style={{ marginTop: 8, marginBottom: 8, textAlign: "right" }}>
              <Tooltip title={"Restore to site sequence configuration"}>
                <IconButton
                  color={"primary"}
                  onClick={() => {
                    setSnsState(globalSNS);
                    isRestore.current = true;
                  }}
                  style={{ marginRight: 10, padding: 0 }}
                >
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Go to site sequence configuration"}>
                <IconButton
                  color={"primary"}
                  onClick={() => {
                    onOpenSNS();
                    // setSnsState(globalSNS);
                    // isRestore.current = true;
                  }}
                  style={{ padding: 0 }}
                >
                  <SettingsOutlined />
                </IconButton>
              </Tooltip>
            </div>
            <ReactSortable
              list={snsState.orderList}
              setList={(newState) => {
                const newSnSState = { ...snsState, orderList: newState };
                setSnsState(newSnSState);
                isRestore.current = false;
              }}
              animation={200}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                flexWrap: "wrap",
              }}
              delayOnTouchStart={true}
              delay={2}
              handle=".sorting-handle"
            >
              {snsState.orderList.map(
                (
                  {
                    id,
                    label,
                    pre,
                    post,
                    isArray,
                    isEditable,
                    strict,
                    style,
                    help,
                    max,
                    alwaysSelected,
                    autoCompleteOptions,
                    visible,
                  },
                  index
                ) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        width: "100%",
                      }}
                    >
                      <div>
                        <Checkbox
                          color={"primary"}
                          icon={<VisibilityOffOutlined />}
                          checkedIcon={<Visibility />}
                          checked={visible}
                          style={{
                            visibility: Boolean(alwaysSelected)
                              ? "hidden"
                              : "visible",
                            padding: 0,
                          }}
                          onChange={(e) =>
                            onChangeVisibility(index, e.target.checked)
                          }
                        />
                      </div>
                      <div style={{ flex: 1, overflow: "hidden" }}>
                        <NameLine
                          key={id}
                          pre={pre}
                          post={post}
                          value={
                            isArray
                              ? namesState[id]?.map((label) => ({
                                  id: label,
                                  label,
                                }))
                              : namesState[id]
                          }
                          isArray={isArray}
                          isEditable={isEditable}
                          label={label}
                          style={style}
                          strict={strict}
                          autoCompleteOptions={autoCompleteOptions}
                          max={max}
                          help={help}
                          onChange={(value) => {
                            handleChange(value, id);
                          }}
                          visible={visible}
                        />
                      </div>

                      <div>
                        <IconButton
                          className={"sorting-handle"}
                          style={{ cursor: "move", padding: 0 }}
                        >
                          <Reorder />
                        </IconButton>
                      </div>
                    </div>
                  );
                }
              )}
            </ReactSortable>
          </div>
        </div>
      }
      footer={
        null
        // <div>
        //   <Button
        //     variant={"contained"}
        //     color={"primary"}
        //     onClick={() => onSave(namesState)}
        //   >
        //     SAVE
        //   </Button>
        // </div>
      }
    />
  );
}
