import React from 'react'
import { Backdrop, Typography, Button, Box } from '@material-ui/core';

export default function DisablePopup({ open, isPrivacy, handleClose }) {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div>
        <Typography variant="h5" component="h5" style={{ color: '#fff', textAlign: 'center' }}>
          Those who decline this {isPrivacy ? "Privacy Policy" : "License Agreement and the Terms and Conditions"} are expressly prohibited from using this Anatomy Mapper application.
        </Typography>
        <Typography variant="h5" component="h5" style={{ color: '#fff', textAlign: 'center' }}>
          Please close your browser if you wish to Decline or visit the Anatomy Mapper landing page.
        </Typography>
        <a style={{ color: '#fff', textAlign: 'center', display: 'block', fontSize: '22px', marginTop: '20px' }} href="https://anatomymapper.com"> https://anatomymapper.com </a>

        <Typography variant="h5" component="h5" style={{ color: '#fff', textAlign: 'center', marginTop: '20px' }}>
          OR
        </Typography>
        <Box display="flex" justifyContent="center" m={1}>
          <Button onClick={handleClose} variant="contained" color="primary">Review again</Button>
        </Box>
      </div>
    </Backdrop>
  )
}
