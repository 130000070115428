import { useState } from "react";
import { SketchPicker } from "react-color";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import "./color.css";
import { IconButton } from "@material-ui/core";

const Colors = ({ color, onColorChange }) => {
  const [element, setElement] = useState(null);

  const handleClick = (event) => {
    console.log(event);
    event.stopPropagation();

    setElement(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setElement(null);
  };

  return (
    <div className="color-picker-container" style={{ backgroundColor: color }}>
      <div className="color-picker-overlay" onClick={handleClick}></div>
      <Popover open={element != null} anchorEl={element} onClose={handleClose}>
        <div
          className="color-picker"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon className="color-picker-close" fontSize="small" />
          </IconButton>
          <SketchPicker
            disableAlpha={true}
            color={color}
            onChangeComplete={(color) => {
              onColorChange(color.hex);
            }}
          />
        </div>
      </Popover>
    </div>
  );
};

export default Colors;
