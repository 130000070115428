import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useReducer,
} from "react";
import "./css/app.css";
import { isEqual } from "lodash";
import "./css/global.css";
import AnatomyMapperMain from "./AnatomyMapperMain";
import ListContent from "./ListContent";
import appLogo from "./assets/svg/AnatomyMapperLogoWL.svg";
import ExportList from "./components/ExportList/ExportList";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Tooltip,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { Help, Reorder } from "@material-ui/icons";
import { SplitButton } from "@progress/kendo-react-buttons";
import "./splitButton.css";

// import "date-fns";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactSortable } from "react-sortablejs";
// import { saveAs } from "file-saver";

import domtoimage from "dom-to-image";
import Preview from "./components/PDFPreview/Preview";
import html2pdf from "html2pdf.js";
import Logo from "./assets/logo.png";
import moment from "moment";
import GroupedItems from "./components/GroupedListComponents/GroupedItems/GroupedItems";
import { cloneDeep } from "lodash";
import Colors from "./components/ColorComponent/Colors";
import DeleteListContent from "./DeleteListContent";
import AdditionalDocumentation from "./components/GroupedListComponents/AdditionalDocumentation/AdditionalDocumentation";
import {
  copyAndChangeArray,
  generateFormDataFromSearchQuery,
  getClassOfDroppedPin,
  getDate,
  getDirectionInWords,
  getLabel,
  getMarkerRadius,
} from "./utils/cf";
import { groupAttributes } from "./utils/groupListUtils";
import { getInputsForGroupedList } from "./utils/dataController";
import { getNameFromId } from "./utils/getNameFromId";
import calculateAge from "./utils/calculateAge";
import getTime from "./utils/getTime";
import useFetch from "./hooks/useFetch";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import DisablePopup from "./components/DisablePopup";
import BackdropForDownload from "./components/BackdropForDownload";
import AvatarComponent from "./components/AvatarComponent/AvatarComponent";
import NameBuilder from "./components/NameBuilder";
import { SNSItems, TERMS_URL } from "./assets/data-files/constants";
import SNSConfiguration from "./components/SNSConfiguration";

// =========================
// Material-UI Designs
// =========================
const MapSwitch = withStyles({
  switchBase: {
    color: `#afb6e0`,
    "&$checked": {
      color: `#0c27c8`,
    },
    "&$checked + $track": {
      backgroundColor: `#0c28c89d`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "white",
  },
}));

const useTextfieldStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const useGeneralStyles = makeStyles(() => ({
  profileInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  profileInfoAvatarContainer: {
    padding: "0px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const List = createContext({ patientInfo: {} });

export const globalSNSInitialState = {
  autoEnhance: true,
  autoRelate: true,
  orderList: Object.keys(SNSItems).map((key) => SNSItems[key]),
};

const nameBuilderInitialState = {
  openModal: false,
  names: {
    laterality: [],
    prefix: [],
    suffix: [],
    enhance_modifier: [],
    anatomic_site: "",
  },
  defaults: {
    laterality: [],
  },
  listContentInfo: {
    index: null,
    id: null,
    listType: null,
    listSubType: null,
  },
  sns: globalSNSInitialState,
};
const dispatchNameBuilder = (state, action) => {
  switch (action.type) {
    case "close":
      return nameBuilderInitialState;
    case "open":
      return {
        openModal: true,
        names: {
          ...action.names,
        },
        listContentInfo: action.listContentInfo,
        defaults: {
          ...action.defaults,
        },
        sns: action.sns,
      };
    default:
      return nameBuilderInitialState;
  }
};

const snsConfigInitialState = {
  openModal: false,
  sns: {
    autoEnhance: false,
    orderList: [],
  },
  listContentInfo: null,
};

const dispatchSNS = (state, action) => {
  switch (action.type) {
    case "close":
      return snsConfigInitialState;

    case "open":
      return {
        openModal: true,
        sns: action.sns,
        listContentInfo: action.listContentInfo,
      };

    default:
      return snsConfigInitialState;
  }
};

const patchSNSObject = (sns, amid) => {
  const newSNS = { ...sns };
  newSNS.orderList = newSNS.orderList.map((datum) => {
    if (datum.id === SNSItems.ANATOMIC_SITE_NAME.id) {
      return {
        ...datum,
        label:
          typeof datum.label === "string" ? (
            <span>
              {datum.label}{" "}
              <a
                href={`${TERMS_URL}/#${amid}`}
                target={"_blank"}
                rel={"noreferrer"}
              >
                ({amid})
              </a>
            </span>
          ) : (
            datum.label
          ),
      };
    } else {
      return datum;
    }
  });
  console.log(newSNS);
  return newSNS;
};

function App() {
  const patientFormDetails = generateFormDataFromSearchQuery();
  const [patientImg, setPatientImg] = useState(
    localStorage.getItem("patient_img") || null
  );

  const [allSNS, setAllSNS] = useState({});
  // =========================
  // useStates
  // =========================

  // Controls mapped element changes
  const [mapType, setMapType] = useState("standalone");
  const [fittingImage, setFittingImage] = useState(false);
  // Controls List type changes
  const [listType, setListType] = useState("ordered");

  const [groupedType, setGroupedType] = useState("procedures");
  // Controls Language changes
  const [language, setLanguage] = useState("English");
  // Holds ordered list contents
  const [orderedListContents, setOrderedListContents] = useState([]);
  // Holds unordered list contents
  const [unorderedListContents] = useState([]);
  const [commentListContents, setCommentListContents] = useState([]);
  const [diagnosisListContents, setDiagnosisListContents] = useState([]);
  const [groupedList, setGroupedList] = useState([]); // For Both Grouped Diagnosis and Grouped Procedurs
  const [orderOfList, setOrderOfList] = useState([]);
  const [arNames, setARNames] = useState({});

  const [nb, dispatchNBActions] = useReducer(
    dispatchNameBuilder,
    nameBuilderInitialState
  );

  const [snsConfig, dispatchSNSActions] = useReducer(
    dispatchSNS,
    snsConfigInitialState
  );

  const [globalSNS, setGlobalSNS] = useState(globalSNSInitialState);

  // Hold ordered list order
  const [listOrderDescending, setlistOrderDescending] = useState(false);
  // Hold loader state
  const [loader, setLoader] = useState(false);
  const [download] = useState(false);
  const [header, setHeader] = useState("");
  const [img, setImg] = useState("");

  const [orderedType, setOrderedType] = useState("Shave");
  const [unorderedType, setUnorderedType] = useState("Diagnosis-Nevus");
  const [commentsType, setCommentsType] = useState("Shave");
  const [diagnosisType, setDiagnosisType] = useState("Diagnosis-Nevus");

  const [splitIndex, setSplitIndex] = useState(0);
  const [exportSplitIndex, setExportSplitIndex] = useState(0);
  const [loadSplitIndex, setLoadSplitIndex] = useState(0);

  // Handles the clear list alert box opening and closing
  const [open, setOpen] = useState(false);
  // Handles the workOnSVG icon activation
  const [workOnSVGActive, setWorkOnSVGActive] = useState(true);
  // Holds Patient Info
  const [patientInfo, setPatientInfo] = useState(
    patientFormDetails.patientInfo
  );
  const [userSettings, setUserSettings] = useState(
    patientFormDetails.userSettings
  );
  const [encounterInfo, setEncounterInfo] = useState(
    patientFormDetails.encounterInfo
  );

  // Handles visibility of opposite gender anatomy
  const [hideOppositeGenderAnatomy, setHideOppositeGenderAnatomy] = useState(
    patientFormDetails.patientInfo.hideOpposite
  );

  const [isOralAnatomyVisible, setIsOralAnatomyVisible] = useState(
    patientFormDetails.patientInfo.showOral
  );

  const [selectedDate] = useState(new Date());

  const [showListContents, setShowListContents] = useState(false);

  const [deleteList, setDeleteList] = useState([]);

  // Colors
  const [orderedListColor, setOrderedListColor] = useState("#FF0000");
  const [commentListColor, setCommentListColor] = useState("#333333");
  const [diagnosisListColor, setDiagnosisListColor] = useState("#3244a8");

  // getting the IpAddress of user
  const { isLoading, apiData } = useFetch("https://geolocation-db.com/json/");

  // Dialogs useState
  const [openlt, setOpenlt] = useState(() => {
    return userSettings.acceptLic ? false : true;
  });
  const [openpp, setOpenpp] = useState(() => {
    return userSettings.acceptStatement ? false : true;
  });

  const [emailError, setEmailError] = useState("");

  const [isNotAgreed, setIsNotAgreed] = useState({});

  useEffect(() => {
    if (isLoading === false) {
      setEncounterInfo((e) => ({
        ...e,
        IPaddress: apiData?.IPv4,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const groupedArr = groupedList.reduce((prevValue, currentValue) => {
      return [...prevValue, ...currentValue.items];
    }, []);
    const fullNameCache = {};
    const mergedListItems = [
      ...diagnosisListContents,
      ...commentListContents,
      ...orderedListContents,
      ...groupedArr,
    ];
    mergedListItems.forEach(
      ({ droppedPin__id, content_id, defined_name, attrInfo }) => {
        let name = "";
        if (allSNS[droppedPin__id]) {
          name = defined_name.getFullName(allSNS[droppedPin__id], true);
        } else {
          name = defined_name.getFullName(globalSNS, true);
        }
        if (fullNameCache[name]) {
          fullNameCache[name].push({
            droppedPin__id,
            content_id,
            deviation: attrInfo.deviation,
          });
        } else {
          fullNameCache[name] = [
            { droppedPin__id, content_id, deviation: attrInfo.deviation },
          ];
        }
      }
    );
    const newARS = {};
    Object.keys(fullNameCache).forEach((key) => {
      const list = fullNameCache[key];
      if (list.length === 1) {
        return;
      } else {
        list.forEach(
          ({ droppedPin__id: tdI, content_id: cId, deviation: dev }, index) => {
            newARS[tdI] = [];
            const sourceLabel = getLabel(tdI);
            list.forEach(({ droppedPin__id, content_id, deviation }) => {
              if (tdI === droppedPin__id) {
                return;
              } else {
                const label = getLabel(droppedPin__id);
                if (content_id !== cId) {
                  newARS[tdI].push(
                    sourceLabel + " and " + label + " are not on same map"
                  );
                } else {
                  const { x, y } = getDirectionInWords(deviation, dev);
                  newARS[tdI].push(
                    y || x
                      ? `${sourceLabel} (this pin) is ${y ? y : ""} ${
                          y && x ? "and" : ""
                        } ${x ? x : ""} to ${label};`
                      : `${sourceLabel} and ${label} are too close`
                  );
                }
              }
            });
          }
        );
      }
    });

    setARNames(newARS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSNS, allSNS]);

  const accordionClasses = useAccordionStyles();
  const textfieldClasses = useTextfieldStyles();
  const classes = useGeneralStyles();

  const exportedListRef = useRef();

  const splitItems = [
    {
      text: "Clear Patient Info",
    },
    {
      text: "Clear User Settings",
    },
    {
      text: "Clear Encounter form",
    },
    {
      text: "Clear List",
    },
    {
      text: "Clear All",
    },
  ];

  const exportSplitItems = [
    {
      text: "Export PDF with Map",
    },
    {
      text: "Export Text Lists",
    },
  ];

  const loadSplitItems = [
    {
      text: "Query String",
    },
    {
      text: "AMAPS",
    },
    {
      text: "Points by coordinates",
    },
  ];

  // =========================
  // Event Handlers
  // =========================
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleMapChange = (event) => {
    let isCkecked = event.target.checked;
    setMapType(isCkecked ? "hierarchy" : "standalone");
  };

  const handleListOrdering = (type = "") => {
    setOrderedListContents(orderedListContents.reverse());
    setCommentListContents(commentListContents.reverse());
    setDiagnosisListContents(diagnosisListContents.reverse());
    setDeleteList(deleteList.reverse());
    setlistOrderDescending(!listOrderDescending);
    setAllSNS({ ...allSNS });
    // setARNames({ ...arNames });
  };

  const sketchRef = useRef();

  // Sorts the the based on list ordering in ascending or descending order
  const sortList = (listContents) => {
    return listContents.map((content, index) => {
      if (content.list__type === "ordered") {
        document.querySelector(`#${content.droppedPin__id}>g>text`).innerHTML =
          listOrderDescending
            ? String.fromCharCode(64 + (listContents.length - index))
            : String.fromCharCode(65 + index);
      } else if (
        content.list__type === "comments" ||
        content.list__type === "diagnosis"
      ) {
        document.querySelector(`#${content.droppedPin__id}>g>text`).innerHTML =
          listOrderDescending ? 1 + (listContents.length - index) : 1 + index;
      }
      return {
        ...content,
        id: listOrderDescending ? listContents.length - index : index + 1,
      };
    });
  };

  // Updates the list on new dropped pin over the SVG
  const updateList = (droppedPinData, actualListType = "", undo = false) => {
    actualListType = actualListType === "" ? listType : actualListType;
    if (allSNS[droppedPinData.droppedPin__id]) {
      setAllSNS({
        ...allSNS,
        [droppedPinData.droppedPin__id]: allSNS[droppedPinData.droppedPin__id],
      });
    } else {
      setAllSNS({ ...allSNS, [droppedPinData.droppedPin__id]: null });
    }
    if (actualListType === "ordered") {
      let newList = [...orderedListContents];
      if (newList.length === 0) {
        orderOfList.push({ typeOfList: "ordered", indexOfItem: null });
      }
      let newContent = {
        id: droppedPinData.id,
        biopsy__type: orderedType,
        anatomic__site: undo
          ? droppedPinData.anatomic__site
          : getNameFromId(droppedPinData.content_id),
        list__type: actualListType,
        user__notes: "",
        droppedPin__id: droppedPinData.droppedPin__id,
        content_id: droppedPinData.content_id,
        hierarchy: droppedPinData.hierarchy,
        attrInfo: droppedPinData.attrInfo,
        native_name: droppedPinData.names,
        defined_name: droppedPinData.names,
      };
      newList.push(newContent);
      setOrderedListContents(sortList(newList));
      // console.log(newContent);
    } else if (actualListType === "comments") {
      let newList = [...commentListContents];
      if (newList.length === 0) {
        orderOfList.push({ typeOfList: "comments", indexOfItem: null });
      }

      let newContent = {
        coords: droppedPinData.coords,
        id: droppedPinData.id,
        biopsy__type: commentsType,
        anatomic__site: undo
          ? droppedPinData.anatomic__site
          : getNameFromId(droppedPinData.content_id),
        list__type: actualListType,
        user__notes: "",
        content_id: droppedPinData.content_id,

        droppedPin__id: droppedPinData.droppedPin__id,
        hierarchy: droppedPinData.hierarchy,
        attrInfo: droppedPinData.attrInfo,
        native_name: droppedPinData.names,
        defined_name: droppedPinData.names,
      };
      newList.push(newContent);
      setCommentListContents(sortList(newList));
      // console.log(newContent);
    } else if (actualListType === "diagnosis") {
      let newList = [...diagnosisListContents];
      if (newList.length === 0) {
        orderOfList.push({ typeOfList: "diagnosis", indexOfItem: null });
      }
      let newContent = {
        coords: droppedPinData.coords,
        id: droppedPinData.id,
        biopsy__type: diagnosisType,
        anatomic__site: undo
          ? droppedPinData.anatomic__site
          : getNameFromId(droppedPinData.content_id),
        list__type: actualListType,
        user__notes: "",
        droppedPin__id: droppedPinData.droppedPin__id,
        content_id: droppedPinData.content_id,

        hierarchy: droppedPinData.hierarchy,
        attrInfo: droppedPinData.attrInfo,
        native_name: droppedPinData.names,
        defined_name: droppedPinData.names,
      };
      newList.push(newContent);
      setDiagnosisListContents(sortList(newList));
      // console.log(newContent);
    } else {
      const grpArr = groupedList;

      let newContent = {
        id: droppedPinData.id,
        coords: droppedPinData.coords,
        biopsy__type: unorderedType,
        anatomic__site: undo
          ? droppedPinData.anatomic__site
          : getNameFromId(droppedPinData.content_id),
        list__type: listType,
        user__notes: "",
        droppedPin__id: droppedPinData.droppedPin__id,
        hierarchy: droppedPinData.hierarchy,

        content_id: droppedPinData.content_id,
        inputs: getInputsForGroupedList(unorderedType),
        attrInfo: droppedPinData.attrInfo,
        native_name: droppedPinData.names,
        defined_name: droppedPinData.names,
      };

      let grpObj = grpArr.filter((g) => {
        return g.name === newContent.biopsy__type;
      })[0];

      if (grpObj === undefined) {
        let gname = newContent.biopsy__type;
        orderOfList.push({ typeOfList: "grouped", indexOfItem: grpArr.length });
        grpObj = {
          ...groupAttributes[gname],
          id: gname,
          items: [newContent],
        };
        grpArr.push(grpObj);
      } else {
        grpObj.items.push(newContent);
      }
      setGroupedList(sortList(grpArr));
    }
  };

  // Handles the clear lists alert dialog box opening
  const handleClickOpen = () => {
    (orderedListContents.length || groupedList.length) && setOpen(true);
  };

  const setList = (listInfo = {}, values) => {
    switch (listInfo.listType) {
      case "ordered": {
        setOrderedListContents(
          copyAndChangeArray(orderedListContents, listInfo.id, values)
        );
        break;
      }

      case "grouped": {
        break;
      }
      case "diagnosis": {
        setDiagnosisListContents(
          copyAndChangeArray(diagnosisListContents, listInfo.id, values)
        );
        break;
      }
      case "comments": {
        setCommentListContents(
          copyAndChangeArray(commentListContents, listInfo.id, values)
        );
        break;
      }
      default: {
      }
    }
  };

  // const setAllList = (values)=>{
  //   setOrderedListContents(
  //     olc=>olc.map((item)=>({...item,...values}))
  //   );
  //   setCommentListContents(olc=>olc.map((item)=>({...item,...values})))
  //   setDiagnosisListContents(olc=>olc.map((item)=>({...item,...values})))
  //   // #To Do GroupedList

  //   setGroupedList((gl)=>gl.map((gle)=>({...gle,items: [...gle.items.map((item)=>({...item,...values}))]})))
  // }

  // Handles the clear lists alert dialog box closing
  const handleClose = () => {
    setOpen(false);
  };

  const handleClearList = () => {
    setOpen(false);

    orderedListContents &&
      orderedListContents.forEach((content) => {
        document.getElementById(content.droppedPin__id).remove();
        // document.getElementById(content.droppedPin__id + "__label").remove();
      });
    groupedList &&
      groupedList.forEach((g) => {
        g.items.forEach((content) => {
          document.getElementById(content.droppedPin__id).remove();
        });
      });

    commentListContents &&
      commentListContents.forEach((content) => {
        document.getElementById(content.droppedPin__id).remove();
        // document.getElementById(content.droppedPin__).remove();
      });

    setOrderedListContents([]);
    setGroupedList([]);
    setCommentListContents([]);
    setAllSNS({});
    setARNames({});
  };

  const handleWorkOnSVGActive = () => {
    setWorkOnSVGActive(!workOnSVGActive);
  };

  const handleHideOppositeGenderAnatomy = () => {
    let gender = patientInfo.gender;

    if (gender) {
      setHideOppositeGenderAnatomy(!hideOppositeGenderAnatomy);
    } else {
      alert("Choose Your sex to Activate this Option.");
    }
  };

  const showMaleAnatomy = (isShow = true) => {
    setTimeout(() => {
      const display = isShow ? "block" : "none";

      if (
        document.getElementById("HG-Male_Anatomy") &&
        document.getElementById("G-Male_Anatomy")
      ) {
        document.getElementById("HG-Male_Anatomy").style.display = display;
        document.getElementById("G-Male_Anatomy").style.display = display;
      }
    }, 1000);
  };

  const showFemaleAnatomy = (isShow = true) => {
    setTimeout(() => {
      const display = isShow ? "block" : "none";
      if (
        document.getElementById("HG-Female_Anatomy") &&
        document.getElementById("G-Female_Anatomy")
      ) {
        document.getElementById("HG-Female_Anatomy").style.display = display;
        document.getElementById("G-Female_Anatomy").style.display = display;
      }
    }, 1000);
  };

  const showOralAnatomy = (isShow = true) => {
    setTimeout(() => {
      const display = isShow ? "block" : "none";
      if (
        document.getElementById("HG-Oral_Anatomy") &&
        document.getElementById("G-Oral_Anatomy")
      ) {
        document.getElementById("HG-Oral_Anatomy").style.display = display;
        document.getElementById("G-Oral_Anatomy").style.display = display;
      }
    }, 1000);
  };

  const transferPoints = (
    newId = "",
    droppedPinId = "",
    typeOfList = "",
    index = 0
  ) => {
    // console.log(newId,droppedPinId);
    // Index is used in case of grouped
    // Updating List

    let newList = [];
    if (typeOfList === "ordered") {
      newList = orderedListContents.map((el, index) => {
        if (el.droppedPin__id === droppedPinId) {
          // console.log(el);
          // removeFromDuplicateList(el.content_id, droppedPinId);
          return {
            ...el,
            content_id: newId,
            anatomic__site: getNameFromId(newId),
            hierarchy: [
              ...el.hierarchy.filter((id) => id !== newId),
              el.content_id,
            ],

            // ...getParentsAndChildren(newId),
          };
        }
        return el;
      });
      setOrderedListContents(sortList(newList));
    } else if (typeOfList === "grouped") {
      const newGroupedList = [...groupedList];
      const newItems = groupedList[index].items.map((item) => {
        if (item.droppedPin__id === droppedPinId) {
          // removeFromDuplicateList(item.content_id, droppedPinId);

          return {
            ...item,
            content_id: newId,
            anatomic__site: getNameFromId(newId),
            hierarchy: [
              ...item.hierarchy.filter((id) => id !== newId),
              item.content_id,
            ],

            // ...getParentsAndChildren(newId),
          };
        } else {
          return item;
        }
      });
      newGroupedList[index] = {
        ...newGroupedList[index],
        items: newItems,
      };
      // console.log(newGroupedList);
      setGroupedList(newGroupedList);
    } else if (typeOfList === "comments") {
      newList = commentListContents.map((el, index) => {
        if (el.droppedPin__id === droppedPinId) {
          // removeFromDuplicateList(el.content_id, droppedPinId);
          return {
            ...el,
            content_id: newId,
            anatomic__site: getNameFromId(newId),
            hierarchy: [
              ...el.hierarchy.filter((id) => id !== newId),
              el.content_id,
            ],

            // ...getParentsAndChildren(newId),
          };
        }
        return el;
      });
      setCommentListContents(sortList(newList));
    } else if (typeOfList === "diagnosis") {
      newList = diagnosisListContents.map((el, index) => {
        if (el.droppedPin__id === droppedPinId) {
          return {
            ...el,
            content_id: newId,
            anatomic__site: getNameFromId(newId),
            hierarchy: [
              ...el.hierarchy.filter((id) => id !== newId),
              el.content_id,
            ],

            // ...getParentsAndChildren(newId),
          };
        }
        return el;
      });
      setDiagnosisListContents(sortList(newList));
    }

    // setTimeout(()=>{

    // }, 3000);
  };

  useEffect(() => {
    let gender = patientInfo.gender;
    if (gender) {
      // console.log(getParentsAndChildren('37-L._Face_HL3'));

      setPatientInfo((old) => ({
        ...old,
        hideOpposite: hideOppositeGenderAnatomy,
      }));

      if (hideOppositeGenderAnatomy) {
        if (gender === "male") {
          showMaleAnatomy(true);
          showFemaleAnatomy(false);
        } else if (gender === "female") {
          showFemaleAnatomy(true);
          showMaleAnatomy(false);
        } else {
          showFemaleAnatomy(true);
          showMaleAnatomy(true);
        }
      } else {
        showMaleAnatomy(true);
        showFemaleAnatomy(true);
      }
    }
  }, [patientInfo.gender, hideOppositeGenderAnatomy]);

  // For saving the PDF ------------------------

  useEffect(() => {
    if (loader && header !== "") {
      // console.log("Will print after 1500ms");
      setTimeout(() => {
        savePDF(header);
        setHeader("");
        setLoader(false);
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, header]);

  useEffect(() => {
    showOralAnatomy(isOralAnatomyVisible);
    setPatientInfo((old) => ({
      ...old,
      showOral: isOralAnatomyVisible,
    }));
  }, [isOralAnatomyVisible]);

  useEffect(() => {
    const pI = JSON.stringify(patientInfo);
    const eI = JSON.stringify(encounterInfo);
    const us = JSON.stringify(userSettings);
    // console.log(encounterInfo);
    localStorage.setItem("patientInfo", pI);
    localStorage.setItem("encounterInfo", eI);
    localStorage.setItem("userSettings", us);
  }, [patientInfo, encounterInfo, userSettings]);

  useEffect(() => {
    setEncounterInfo((e) => ({
      ...e,
      sessionID: e.sessionID + 1,
    }));
  }, []);

  const onLocatePin = (droppedPinId) => {
    if (document.querySelector("#clicked_marker")) {
      document.querySelector("#clicked_marker").remove();
    }
    const classId = getClassOfDroppedPin(droppedPinId);
    const SCALE = 4;

    const rootSVG = document.querySelector("#loaded-svg-cont").firstChild;
    const NS = rootSVG.getAttribute("xmlns");
    const pt = rootSVG.createSVGPoint();
    const { x, y } = document
      .querySelector(`#${droppedPinId} .Pin-Marker`)
      .getBoundingClientRect();
    pt.x = x;
    pt.y = y;
    const svgP = pt.matrixTransform(rootSVG.getScreenCTM().inverse());
    const svg = document.createElementNS(NS, "svg");
    const circle = document.createElementNS(NS, "circle");
    const radiusOfMarker = getMarkerRadius(classId);
    circle.setAttribute("cx", radiusOfMarker);
    circle.setAttribute("cy", radiusOfMarker);
    const radius = 15 * SCALE;

    circle.setAttribute("r", radius);
    circle.setAttribute("fill", "transparent");
    circle.setAttribute("stroke", "orange");
    circle.setAttribute("stroke-width", "5");
    circle.classList.add("flash-animation");

    svg.setAttribute("x", sketchRef.current.getPointOnSVG(svgP.x));
    svg.setAttribute("y", sketchRef.current.getPointOnSVG(svgP.y));

    svg.setAttribute("id", "clicked_marker");
    svg.style.overflow = "visible";
    svg.appendChild(circle);
    rootSVG.appendChild(svg);

    sketchRef.current.panAndZoomToARegion(svg, () => {
      setTimeout(() => {
        circle.remove();
      }, 800);
    });
  };

  const savePDF = (datauri) => {
    var element = document.getElementById("pdf-preview");
    var opt = {
      margin: [1.3, 0.3, 0.55, 0.3],
      filename: "anatomymapper.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: "avoid-all", before: "#pdf-preview" },
    };

    var clonedElement = element.cloneNode(true);
    clonedElement.style.display = "block";

    // html2canvas(document.getElementById('headerPDF'), {
    //   onclone: function (doc) {
    //     console.log(doc)
    //   }
    // }).then((canvas) => {
    //   let imgData = canvas.toDataURL('image/png')
    //   setHeader(imgData)
    // })

    let fileNameDOB = getDate(patientInfo.DOB);

    html2pdf()
      .from(clonedElement)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.addImage(datauri, "PNG", 0.46, 0.3);
        }
        if (fileNameDOB === "") {
          pdf.save(fileName2());
        } else if (fileNameDOB !== "") {
          pdf.save(fileName());
        }
      });
  };

  const fileName = () => {
    return (
      getDate(new Date()) +
      "-" +
      "AnatomyMapper" +
      "-" +
      patientInfo.firstName +
      "-" +
      patientInfo.lastName +
      "-DOB" +
      getDate(patientInfo.DOB) +
      "-MRN" +
      patientInfo.MRN
    );
  };

  const fileName2 = () => {
    return (
      getDate(new Date()) +
      "-" +
      "AnatomyMapper" +
      "-" +
      patientInfo.firstName +
      "-" +
      patientInfo.lastName +
      "-MRN" +
      patientInfo.MRN
    );
  };

  // For getting the image of map component ------------------------

  const getImage = () => {
    setLoader(true);
    var node = document.getElementById("map-container");
    var headerNode = document.getElementById("headerPDF");
    document.getElementById("reset-zoom-pan").click();
    setLoader(true);
    setTimeout(async () => {
      // setFittingImage(false);
      let res = await domtoimage.toSvg(node, {
        height: node.getBoundingClientRect().height,
        width: node.getBoundingClientRect().width,
        quality: 1.0,
      });
      let resHeader = await domtoimage.toPng(headerNode, {
        height: headerNode.getBoundingClientRect().height,
        width: headerNode.getBoundingClientRect().width,
        quality: 1.0,
      });
      setImg(res);
      setLoader(false);

      savePDF(resHeader);
      // setLoader(false);
      setHeader(resHeader);
    }, 3000);
  };

  // Create a text file with the conent of the lists
  /*
  const createTextFile = () => {
    let textFileArray = ["Ordered List\n------------\n"];
    orderedListContents.map(
      ({ id, biopsy__type, anatomic__site, user__notes }) => {
        textFileArray.push(
          `${id}. ${anatomic__site} - ${biopsy__type} Biopsy\n   Note: ${user__notes}\n\n`
        );
      }
    );

    textFileArray.push("\nUnordered List\n--------------\n");
    unorderedListContents.map(
      ({ id, biopsy__type, anatomic__site, user__notes }) => {
        textFileArray.push(
          `• ${anatomic__site} - ${biopsy__type} Biopsy\n   Note: ${user__notes}\n\n`
        );
      }
    );
    var blob = new Blob(textFileArray, {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "Anatomy Mapper.txt");
  };
  */

  const getAge = (birthdayDate) => {
    let today = new Date();
    let birthDate = new Date(birthdayDate);
    let age = today.getFullYear() - birthDate.getFullYear();

    return age < 0 ? 0 : age;
  };

  useEffect(() => {
    return exportedListRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [showListContents]);

  // Color
  useEffect(() => {
    orderedListContents.forEach(({ droppedPin__id }) => {
      const elem = document.getElementById(droppedPin__id);
      if (elem) {
        elem.getElementsByClassName("Pin-Marker")[0].style.fill =
          orderedListColor;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedListColor, orderedListContents.length]);

  useEffect(() => {
    commentListContents.forEach(({ droppedPin__id }) => {
      const elem = document.getElementById(droppedPin__id);
      if (elem) {
        elem.getElementsByClassName("Pin-Marker")[0].style.fill =
          commentListColor;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentListColor, commentListContents.length]);

  useEffect(() => {
    diagnosisListContents.forEach(({ droppedPin__id }) => {
      const elem = document.getElementById(droppedPin__id);
      if (elem) {
        elem.getElementsByClassName("Pin-Marker")[0].style.fill =
          diagnosisListColor;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisListColor, diagnosisListContents.length]);

  // for policy and terms
  useEffect(() => {
    if (!userSettings.acceptLic) {
      setOpenlt(true);
    }
    if (!userSettings.acceptStatement) {
      setOpenpp(true);
    }
  }, [userSettings.acceptLic, userSettings.acceptStatement]);

  const handleClear = () => {
    let action = splitItems[splitIndex].text;

    switch (action) {
      case "Clear User Settings":
        clearUserForm();
        break;
      case "Clear Encounter form":
        clearEncounterForm();
        break;
      case "Clear Patient Info":
        clearPatientForm();
        break;
      case "Clear List":
        handleClickOpen();
        break;
      case "Clear All":
        clearAll();
        break;
      default:
        break;
    }
  };
  const handleExport = () => {
    let action = exportSplitItems[exportSplitIndex].text;

    switch (action) {
      case "Export PDF with Map":
        getImage();
        break;
      case "Export Text Lists":
        setShowListContents(true);
        exportedListRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  const clearUserForm = () => {
    setUserSettings({
      userEmail: "",
      physicianName: "",
      assistantName: "",
      clinicName: "",
      clinicAddress: "",
      clinicPhone: "",
      clinicFax: "",
      clinicSite: "",
      clinicEmail: "",
      clinicInfo: "",
      clinicLogo: "",
      acceptLic: false,
      acceptStatement: false,
    });
  };

  const clearPatientForm = () => {
    setPatientInfo({
      firstName: "",
      lastName: "",
      DOB: "",
      gender: "",
      MRN: "",
      physicianName: "",
      additionalInfo: "",
    });
    setPatientImg(null);
    localStorage.setItem("patient_img", "");
  };

  const clearEncounterForm = () => {
    setEncounterInfo({
      notes: "",
      sessionID: "",
      dateTime: new Date(),
    });
  };

  const clearAll = () => {
    clearUserForm();
    clearPatientForm();
    clearEncounterForm();
  };

  const addToThrashBin = (item = {}, listType = "", groupType = "") => {
    document.getElementById(item.droppedPin__id) &&
      (document.getElementById(item.droppedPin__id).style.display = "none");

    setAllSNS((ars) => {
      return { ...ars, [item.droppedPin__id]: null };
    });
    setDeleteList([
      ...deleteList,
      {
        item: item,
        listType: listType,
        groupType: groupType,
      },
    ]);
  };

  const deleteOrderOfList = (type = "", index = null) => {
    const newOrderOfList = orderOfList.filter(({ typeOfList, indexOfItem }) => {
      return !(typeOfList === type && indexOfItem === index);
    });
    // console.log(newOrderOfList);
    setOrderOfList(newOrderOfList);
  };

  const getTotalForGroupedList = (grpIndex) => {
    const inputUsed = groupedList[grpIndex].toGetCount;
    if (inputUsed) {
      let sum = 0;
      let label = "";
      groupedList[grpIndex].items.forEach((item) => {
        label = item.inputs[inputUsed]["label"];
        sum += Number(item.inputs[inputUsed].value);
      });
      return { total: sum, label };
    } else {
      return { total: groupedList[grpIndex].length, label: "Length of List" };
    }
  };

  const handleListClick = (type) => {
    setListType(type);
    setWorkOnSVGActive(true);
  };

  const patientInfoJSX = (
    <>
      {patientInfo.firstName && <strong>{patientInfo.firstName}</strong>}{" "}
      {patientInfo.lastName && <strong>{patientInfo.lastName}</strong>}{" "}
      {patientInfo.DOB && encounterInfo.dateTime && (
        <span>({calculateAge(patientInfo.DOB, encounterInfo.dateTime)})</span>
      )}{" "}
      {patientInfo.gender && patientInfo.gender !== "other" && (
        <span>({patientInfo.gender.toUpperCase()[0]})</span>
      )}{" "}
      {patientInfo.DOB && (
        <span>
          | (<i>DOB:</i> {getDate(patientInfo.DOB)})
        </span>
      )}{" "}
    </>
  );
  // console.log(orderOfList);
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("secret") !== "1t5a53cr3t") {
    return (
      <>
        <h1>Forbidden</h1>
        <p>You don't have permission to access this resource.</p>
      </>
    );
  }
  return (
    <>
      <NameBuilder
        names={nb.names}
        onClose={(names, newSns, isRestore) => {
          dispatchNBActions({ type: "close" });
          if (isRestore) {
            setAllSNS({ ...allSNS, [nb.listContentInfo.droppedPin__id]: null });
          } else {
            if (isEqual(newSns, globalSNS)) {
              setAllSNS({ ...allSNS, [nb.listContentInfo]: null });
            } else {
              setAllSNS({
                ...allSNS,
                [nb.listContentInfo.droppedPin__id]: newSns,
              });
            }
          }
          setList(nb.listContentInfo, { defined_name: names });
        }}
        open={nb.openModal}
        sns={nb.sns}
        onOpenSNS={() => {
          dispatchSNSActions({
            type: "open",
            sns: globalSNS,
            listContentInfo: null,
          });
        }}
        globalSNS={globalSNS}
      />

      <SNSConfiguration
        sns={snsConfig.sns}
        onClose={(newSNS, replaceAll = false) => {
          if (snsConfig.openModal) {
            setGlobalSNS(newSNS);
            dispatchSNSActions({ type: "close" });
          }

          if (replaceAll) {
            setAllSNS({});
          }
        }}
        open={snsConfig.openModal}
      />
      {userSettings.acceptLic && userSettings.acceptStatement && (
        <div className="app" id="main-app">
          <Snackbar
            open={fittingImage}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setFittingImage(false)}
          >
            <Alert
              onClose={() => setFittingImage(false)}
              elevation={6}
              variant="filled"
              severity="info"
            >
              Centering the map for export
            </Alert>
          </Snackbar>

          <div style={{ overflow: "hidden", height: "0px" }}>
            <div id="headerPDF" style={{ width: "730px" }}>
              <div className="pdf-header-content">
                <div className="org">
                  <div id="brand" style={{ color: "blue" }}>
                    <span>
                      <img src={Logo} alt="" id="logo" />
                    </span>
                    <h1>anatomy mapper</h1>
                  </div>
                  <p>https://AnatomyMapper.com</p>
                </div>
                <div className="pdf-info">
                  <p>
                    <span>
                      <strong>Encounter Date:</strong>
                    </span>{" "}
                    {getDate(new Date())} at{" "}
                    {moment(selectedDate).format("hh:mm A")}
                    {patientInfo.firstName && patientInfo.lastName && (
                      <span style={{ fontSize: "11px" }}>
                        {" "}
                        record generated for:
                      </span>
                    )}
                    <br />
                    {patientInfo.firstName !== "" &&
                      patientInfo.lastName !== "" && (
                        <span>
                          <span style={{ fontWeight: "700" }}>
                            Patient Name:{" "}
                          </span>
                          <span>
                            {patientInfo.firstName} {patientInfo.lastName}
                            {getDate(patientInfo.DOB) !== getDate(new Date()) &&
                              patientInfo.DOB &&
                              ` (${getAge(patientInfo.DOB)})`}
                            {patientInfo.gender &&
                              ` (${patientInfo.gender[0].toUpperCase()})`}
                          </span>
                        </span>
                      )}
                    {getDate(patientInfo.DOB) !== getDate(new Date()) && (
                      <span>
                        {patientInfo.firstName &&
                          patientInfo.lastName &&
                          ` || `}
                        {patientInfo.DOB && (
                          <span style={{ fontWeight: "700" }}> DOB: </span>
                        )}
                        {getDate(patientInfo.DOB)}
                      </span>
                    )}
                    <br />
                    {patientInfo.MRN && (
                      <span style={{ fontSize: "11px" }}>
                        <span style={{ fontWeight: "700" }}>MRN: </span>
                        {patientInfo.MRN}
                      </span>
                    )}
                    {patientInfo.additionalInfo && (
                      <span>
                        {patientInfo.MRN && ` || `}
                        <span style={{ fontSize: "11px" }}>
                          <span style={{ fontWeight: "700" }}>
                            Additional Notes:{" "}
                          </span>
                          {patientInfo.additionalInfo}
                        </span>
                      </span>
                    )}
                    <br />
                    {patientInfo.physicianName && (
                      <span style={{ fontSize: "11px" }}>
                        <span style={{ fontWeight: "700" }}>
                          Physician/Provider Name:{" "}
                        </span>
                        {patientInfo.physicianName}
                      </span>
                    )}
                    {patientInfo.firstName &&
                      patientInfo.lastName &&
                      patientInfo.DOB &&
                      patientInfo.gender && (
                        <span>
                          {patientInfo.physicianName && ` || `}
                          <span style={{ fontSize: "11px" }}>
                            <span style={{ fontWeight: "700" }}>
                              Generated by:{" "}
                            </span>
                            Note Scribe
                          </span>
                        </span>
                      )}
                  </p>
                </div>
              </div>
              <hr></hr>
            </div>
          </div>

          {/* ========================= */}
          {/* Navbar */}
          {/* ========================= */}
          <nav className="app__nav">
            <div className="app__nav__logo">
              <img src={appLogo} alt="Logo" />
            </div>
            <div className="app__nav__switch__label hide_on_mobile">
              Show All Mapped Elements on Hover
            </div>

            <div className="app__nav__switch hide_on_mobile">
              <FormControlLabel
                control={
                  <MapSwitch
                    checked={mapType === "hierarchy"}
                    onChange={handleMapChange}
                    name="Map Type"
                  />
                }
              />
            </div>

            <div className="app__nav__divider">&nbsp;</div>

            <div className="app__nav__listSelect">
              {/* <div className="app__nav__listSelect__SVG" onClick={handleWorkOnSVG}>
            <Tooltip title={"Work On SVG"}>
              <IconButton color={workOnSVGActive ? "primary" : "default"}>
                <Accessibility style={{ fontSize: 45 }} />
              </IconButton>
            </Tooltip>
          </div> */}

              {/* <div className="app__nav__listSelect__label">Add to: </div> */}

              {/* Select List  */}
              <div className="app__nav__listSelect__switch">
                {listType && (
                  <div
                    className={`list__btn list__btn__selector ${
                      listType === "unordered"
                        ? "unordered " +
                          (groupedType === "procedures"
                            ? "procedures"
                            : "diagnosis")
                        : listType
                    }`}
                  ></div>
                )}

                <div
                  className={`list__btn list__btn__ordered ${
                    listType === "ordered" && "selected"
                  }`}
                  onClick={() => {
                    handleListClick("ordered");
                  }}
                >
                  Ordered Procedure
                  <FormControl
                    className={`dropdown ${
                      listType === "ordered" && "showDropdown"
                    }`}
                  >
                    <Select
                      native
                      value={orderedType}
                      onChange={(e) => setOrderedType(e.target.value)}
                      label="Biopsy"
                      className="list__dropdown"
                    >
                      <option value="Shave-Biopsy">Shave Biopsy</option>
                      <option value="Shave-Removal">Shave Removal</option>
                      <option value="Punch-Biopsy">Punch Biopsy</option>
                      <option value="Punch-Excision">Punch Excision</option>
                      <option value="Excision">Excision</option>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={`list__btn list__btn__unordered grouped ${
                    listType === "unordered" &&
                    groupedType === "procedures" &&
                    "selected"
                  }`}
                  onClick={() => {
                    handleListClick("unordered");
                    setGroupedType("procedures");
                    if (
                      [
                        "Diagnosis-Nevus",
                        "Diagnosis-Acne",
                        "Diagnosis-Psoriasis",
                        "Diagnosis-Eczema",
                        "Skin-cancer",
                        "Custom-Lookup",
                      ].includes(unorderedType)
                    ) {
                      setUnorderedType("Cryo-AK");
                    }
                  }}
                >
                  Grouped Procedure
                  <FormControl
                    className={`dropdown ${
                      listType === "unordered" &&
                      groupedType === "procedures" &&
                      "showDropdown"
                    }`}
                  >
                    <Select
                      native
                      value={unorderedType}
                      onChange={(e) => setUnorderedType(e.target.value)}
                      label="Biopsy"
                      className="list__dropdown"
                    >
                      <option value="Cryo-AK">Cryo - AK</option>
                      <option value="Cryo-Wart">Cryo - Wart</option>
                      <option value="Cryo-ISK">Cryo - ISK</option>
                      <option value="Injection-Med">Injection - Med</option>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={`list__btn list__btn__unordered diagnosis ${
                    listType === "unordered" &&
                    groupedType === "diagnosis" &&
                    "selected"
                  }`}
                  onClick={() => {
                    handleListClick("unordered");
                    setGroupedType("diagnosis");
                    if (
                      [
                        "Cryo-AK",
                        "Cryo-Wart",
                        "Cryo-ISK",
                        "Injection-Med",
                      ].includes(unorderedType)
                    ) {
                      setUnorderedType("Diagnosis-Nevus");
                    }
                  }}
                >
                  Grouped Diagnosis
                  <FormControl
                    className={`dropdown ${
                      listType === "unordered" &&
                      groupedType === "diagnosis" &&
                      "showDropdown"
                    }`}
                  >
                    <Select
                      native
                      value={unorderedType}
                      onChange={(e) => setUnorderedType(e.target.value)}
                      label="Biopsy"
                      className="list__dropdown"
                    >
                      <option value="Diagnosis-Nevus">Nevus</option>
                      <option value="Diagnosis-Acne">Acne</option>
                      <option value="Diagnosis-Psoriasis">Psoriasis</option>
                      <option value="Diagnosis-Eczema">Eczema</option>
                      <option value="Skin-cancer">Skin Cancer</option>
                      <option value="Custom-Lookup">Custom Lookup</option>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={`list__btn list__btn__diagnosis ${
                    listType === "diagnosis" && "selected"
                  }`}
                  onClick={() => handleListClick("diagnosis")}
                >
                  Single Diagnosis
                  <FormControl
                    className={`dropdown ${
                      listType === "diagnosis" && "showDropdown"
                    }`}
                  >
                    <Select
                      native
                      value={diagnosisType}
                      onChange={(e) => setDiagnosisType(e.target.value)}
                      label="Biopsy"
                      className="list__dropdown"
                    >
                      <option value="Diagnosis-Nevus">Nevus</option>
                      <option value="Diagnosis-Acne">Acne</option>
                      <option value="Diagnosis-Psoriasis">Psoriasis</option>
                      <option value="Diagnosis-Eczema">Eczema</option>
                      <option value="Skin-cancer">Skin Cancer</option>
                      <option value="Custom-Lookup">Custom Lookup</option>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={`list__btn list__btn__comments ${
                    listType === "comments" && "selected"
                  }`}
                  onClick={() => handleListClick("comments")}
                >
                  Comment
                  <FormControl
                    className={`dropdown ${
                      listType === "comments" && "showDropdown"
                    }`}
                  >
                    <Select
                      native
                      value={commentsType}
                      onChange={(e) => setCommentsType(e.target.value)}
                      label="Biopsy"
                      className="list__dropdown"
                    >
                      <option value="General-Comment">General Comment</option>
                      <option value="Cosmetic-Comment">Cosmetic Comment</option>
                      <option value="Diagnosis-Comment">
                        Diagnosis Comment
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/* <div className="app__nav__language">
          <FormControl
            className={`${languageClasses.formControl} app__nav__language__dropdown`}
          >
            <InputLabel htmlFor="age-native-simple">Language</InputLabel>
            <Select
              native
              value={language}
              onChange={handleLanguageChange}
              inputProps={{
                name: "language",
                id: "language",
              }}
            >
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
            </Select>
          </FormControl>
        </div> */}
          </nav>

          {/* ========================= */}
          {/* Main Body */}
          {/* ========================= */}
          <List.Provider
            value={{
              orderedListContents,
              groupedList,
              commentListContents,
              diagnosisListContents,
              diagnosisType,
              listType,
              setListType,
              unorderedType,
              orderedType,
              commentsType,
              updateList,
              workOnSVGActive,
              patientInfo,
              handleWorkOnSVGActive,
              globalSNS,
              hoverMode: mapType,
            }}
          >
            <div className="app__mainBody">
              <div className="app__mainBody__SVGMapper">
                <AnatomyMapperMain
                  ref={sketchRef}
                  setMapType={mapType}
                  gender={patientInfo.gender}
                  hideOpposite={patientInfo.hideOpposite}
                  isOralVisible={isOralAnatomyVisible}
                  setOralVisible={setIsOralAnatomyVisible}
                />
              </div>

              <div className="app__mainBody__list">
                <div className={"app__mainBody__list_with-scrollbar"}>
                  <div className="app__mainBody__list__buttons">
                    <div style={{ margin: "10px 0" }}>
                      <SplitButton
                        text="Export"
                        items={exportSplitItems}
                        look="flat"
                        onButtonClick={handleExport}
                        onItemClick={(e) => setExportSplitIndex(e.itemIndex)}
                      >
                        Export
                      </SplitButton>
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <SplitButton
                        text={`${splitItems[splitIndex].text}`}
                        items={splitItems}
                        look="flat"
                        onButtonClick={handleClear}
                        onItemClick={(e) => setSplitIndex(e.itemIndex)}
                      />
                    </div>
                    <div style={{ margin: "10px 0" }}>
                      <SplitButton
                        text={`${loadSplitItems[loadSplitIndex].text}`}
                        items={loadSplitItems}
                        look="flat"
                        onItemClick={(e) => setLoadSplitIndex(e.itemIndex)}
                      />
                    </div>
                  </div>
                  {loader && (
                    <>
                      <LinearProgress />
                    </>
                  )}

                  <div
                    className={`${accordionClasses.root} app__mainBody__list__patient__info__list`}
                  >
                    {/* --------------------------------------------------- FIRST -------------------------------------------------- */}

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="app__mainBody__list__header"
                        style={{ backgroundColor: "#0c27c8" }}
                      >
                        <div className={classes.profileInfoContainer}>
                          <div
                            className={classes.profileInfoAvatarContainer}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={(e) => e.stopPropagation()}
                          >
                            <AvatarComponent
                              gender={patientInfo.gender}
                              patientInfoEle={patientInfoJSX}
                              src={patientImg}
                              onSave={(newImg) => {
                                try {
                                  localStorage.setItem("patient_img", newImg);
                                  setPatientImg(newImg);
                                } catch (err) {
                                  const size = new Blob([newImg]).size;

                                  alert(
                                    "Warning !! Size Exceeded : Size of the image is " +
                                      size +
                                      " (continuing without saving)"
                                  );
                                  setPatientImg(newImg);
                                }
                              }}
                            />
                          </div>
                          <Typography className={accordionClasses.heading}>
                            <strong style={{ fontSize: "1rem" }}>
                              Patient Info:
                            </strong>{" "}
                            <small>
                              {patientInfoJSX}
                              <br />
                              {patientInfo.MRN && (
                                <span>
                                  <i>MRN:</i> {patientInfo.MRN}
                                </span>
                              )}{" "}
                              {patientInfo.additionalInfo && (
                                <span>
                                  | <i>Additional Info:</i>{" "}
                                  {patientInfo.additionalInfo}
                                </span>
                              )}
                            </small>
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="app__mainBody__list__body">
                        <Typography>
                          <form
                            className={textfieldClasses.root}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              id="patient-first-name"
                              label="Patient First Name"
                              value={patientInfo.firstName}
                              onChange={(e) => {
                                setPatientInfo((prev) => {
                                  return {
                                    ...prev,
                                    firstName: e.target.value,
                                  };
                                });
                              }}
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="patient-last-name"
                              label="Patient Last Name"
                              value={patientInfo.lastName}
                              onChange={(e) => {
                                setPatientInfo((prev) => {
                                  return {
                                    ...prev,
                                    lastName: e.target.value,
                                  };
                                });
                              }}
                              style={{ width: "96%" }}
                            />

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <Grid
                                container
                                justify="space-around"
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  width: "96%",
                                }}
                              >
                                <KeyboardDatePicker
                                  margin="normal"
                                  id="date-picker-dialog"
                                  label="Date of Birth"
                                  format="yyyy/MM/DD"
                                  placeholder="2021/12/31"
                                  value={patientInfo.DOB}
                                  onChange={(date) =>
                                    setPatientInfo({
                                      ...patientInfo,
                                      DOB: date,
                                    })
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                />
                                {/* <KeyboardDatePicker
                          invalidDateMessage=""
                          margin="normal"
                          id="date-picker-dialog"
                          placeholder="Patient DOB"
                          format="yyyy/MM/DD"
                          value={patientInfo.DOB}
                          onChange={(date) => {
                            setPatientInfo((prev) => {
                              return {
                                ...prev,
                                DOB: date,
                              };
                            });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          style={{
                            width: "100%",
                            marginTop: "10px",
                          }}
                        /> */}
                              </Grid>
                            </MuiPickersUtilsProvider>

                            <FormControl
                              component="fieldset"
                              style={{ marginBottom: "0px", width: "100%" }}
                            >
                              <FormLabel component="legend">Sex</FormLabel>
                              <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                defaultValue={patientInfo.gender}
                                onChange={(e) => {
                                  setPatientInfo((prev) => {
                                    return {
                                      ...prev,
                                      gender: e.target.value,
                                    };
                                  });
                                }}
                              >
                                <FormControlLabel
                                  value="male"
                                  control={<Radio color="primary" />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="female"
                                  control={<Radio color="primary" />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="other"
                                  control={<Radio color={"primary"} />}
                                  label="Other / Prefer Not To Say"
                                />
                              </RadioGroup>
                            </FormControl>

                            <br />

                            <FormControlLabel
                              disabled={patientInfo.gender === "other"}
                              control={
                                <Checkbox
                                  checked={hideOppositeGenderAnatomy}
                                  onChange={handleHideOppositeGenderAnatomy}
                                  name="Hide Opposite Sex Anatomy"
                                  color="primary"
                                />
                              }
                              style={{
                                marginLeft: "-3.5px",
                                marginBottom: "0px",
                                width: "100%",
                              }}
                              label="Hide Opposite Sex Anatomy on Map"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isOralAnatomyVisible}
                                  onChange={() => {
                                    setIsOralAnatomyVisible(
                                      !isOralAnatomyVisible
                                    );
                                    // showOralAnatomy(!isOralAnatomyVisible)
                                  }}
                                  name="Show Oral Anatomy"
                                  color="primary"
                                />
                              }
                              style={{
                                marginLeft: "-3.5px",
                                marginBottom: "0px",
                                width: "100%",
                              }}
                              label="Show Oral Anatomy"
                            />

                            <TextField
                              id="patient-MRN"
                              label="Patient MRN"
                              value={patientInfo.MRN}
                              onChange={(e) => {
                                setPatientInfo((prev) => {
                                  return {
                                    ...prev,
                                    MRN: e.target.value,
                                  };
                                });
                              }}
                              style={{ width: "96%" }}
                            />

                            {/* <TextField
                      id="physician-name"
                      label="Physician/Provider Name"
                      value={patientInfo.physicianName}
                      onChange={(e) => {
                        setPatientInfo((prev) => {
                          return {
                            ...prev,
                            physicianName: e.target.value,
                          };
                        });
                      }}
                      style={{ width: "96%" }}
                    /> */}

                            <TextField
                              id="additional-patient-info"
                              label="Additional Patient Info"
                              value={patientInfo.additionalInfo}
                              onChange={(e) => {
                                setPatientInfo((prev) => {
                                  return {
                                    ...prev,
                                    additionalInfo: e.target.value,
                                  };
                                });
                              }}
                              style={{ width: "96%" }}
                            />
                          </form>

                          {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid
                      container
                      justify="space-around"
                      style={{ marginBottom: "8px" }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date"
                        format="yyyy/MM/DD"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ width: "45%" }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Time"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        style={{ width: "45%" }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider> */}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    {/* --------------------------------------------------- SECOND -------------------------------------------------- */}

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="app__mainBody__list__header"
                        style={{ backgroundColor: "#0c27c8" }}
                      >
                        <Typography className={accordionClasses.heading}>
                          <strong style={{ fontSize: "1rem" }}>
                            User Settings:
                          </strong>{" "}
                          <small>
                            {userSettings.physicianName && (
                              <strong>{userSettings.physicianName}</strong>
                            )}{" "}
                            {userSettings.assistantName && (
                              <span>
                                | <i>Assistant:</i> {userSettings.assistantName}
                              </span>
                            )}{" "}
                            <br />
                            <span>
                              {userSettings.clinicName && (
                                <>
                                  <i>Clinic Name:</i> {userSettings.clinicName}{" "}
                                </>
                              )}
                              {userSettings.clinicAddress && (
                                <>
                                  {" | "}
                                  {userSettings.clinicAddress}{" "}
                                </>
                              )}
                            </span>
                          </small>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="app__mainBody__list__body">
                        <Typography>
                          <form
                            className={textfieldClasses.root}
                            noValidate
                            autoComplete="off"
                          >
                            <FormControlLabel
                              disabled={patientInfo.gender === "other"}
                              control={
                                <Checkbox
                                  checked={userSettings.acceptLic}
                                  onChange={(e) => {
                                    if (userSettings.acceptLic)
                                      setUserSettings({
                                        ...userSettings,
                                        acceptLic: false,
                                      });

                                    setOpenlt(true);
                                  }}
                                  name="accept"
                                  color="primary"
                                />
                              }
                              style={{
                                marginLeft: "-3.5px",
                                marginBottom: "0px",
                                width: "100%",
                              }}
                              label={
                                <span>
                                  I accept the Anatomy Mapper{" "}
                                  <span
                                    onClick={(e) => setOpenlt(true)}
                                    style={{ color: "blue" }}
                                  >
                                    License and Terms and Conditions
                                  </span>
                                </span>
                              }
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={userSettings.acceptStatement}
                                  onChange={(e) => {
                                    setOpenpp(true);
                                    if (userSettings.acceptStatement)
                                      setUserSettings({
                                        ...userSettings,
                                        acceptStatement: false,
                                      });
                                  }}
                                  name="Show Oral Anatomy"
                                  color="primary"
                                />
                              }
                              style={{
                                marginLeft: "-3.5px",
                                marginBottom: "0px",
                                width: "100%",
                              }}
                              label={
                                <span>
                                  I accept the Anatomy Mapper{" "}
                                  <span
                                    onClick={(e) => setOpenpp(true)}
                                    style={{ color: "blue" }}
                                  >
                                    Privacy Statement
                                  </span>
                                </span>
                              }
                            />
                            <FormControl style={{ width: "96%" }}>
                              <InputLabel htmlFor="age-native-simple">
                                Language
                              </InputLabel>
                              <Select
                                native
                                value={language}
                                onChange={handleLanguageChange}
                                inputProps={{
                                  name: "language",
                                  id: "language",
                                }}
                              >
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                              </Select>
                            </FormControl>
                            <div style={{ width: "100%" }}>
                              <span
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  fontStyle: "italic",
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  dispatchSNSActions({
                                    type: "open",
                                    sns: globalSNS,
                                    listContentInfo: null,
                                  });
                                }}
                              >
                                {" "}
                                Configure Site Naming Sequence{" "}
                              </span>
                            </div>

                            <TextField
                              id="user-email-address"
                              label="User E-Mail Address"
                              value={userSettings.userEmail}
                              onBlur={(e) => {
                                const emailError =
                                  /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
                                    e.target.value
                                  )
                                    ? ""
                                    : "Email is not valid.";
                                setEmailError(emailError);
                              }}
                              onChange={(e) => {
                                setUserSettings({
                                  ...userSettings,
                                  userEmail: e.target.value,
                                });
                                const emailError =
                                  /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(
                                    e.target.value
                                  )
                                    ? ""
                                    : "Email is not valid.";
                                setEmailError(emailError);
                              }}
                              style={{ width: "96%" }}
                              error={emailError}
                              helperText={emailError}
                            />
                            <TextField
                              id="physician-name"
                              label="Physician/Provider Name"
                              value={userSettings.physicianName}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  physicianName: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />
                            <TextField
                              id="assistant-names"
                              label="Assistant Names"
                              value={userSettings.assistantName}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  assistantName: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-name"
                              label="Clinic Name"
                              value={userSettings.clinicName}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicName: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-address"
                              label="Clinic Address"
                              value={userSettings.clinicAddress}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicAddress: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-phone"
                              label="Clinic Phone"
                              value={userSettings.clinicPhone}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicPhone: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-fax"
                              label="Clinic Fax"
                              value={userSettings.clinicFax}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicFax: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-website"
                              label="Clinic Website"
                              value={userSettings.clinicSite}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicSite: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-email"
                              label="Clinic Email Address"
                              value={userSettings.clinicEmail}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicEmail: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              label="Clinic Logo URL"
                              type="url"
                              value={userSettings.clinicLogo}
                              onChange={(e) =>
                                setUserSettings((old) => ({
                                  ...old,
                                  clinicLogo: e.target.value,
                                }))
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Tooltip
                                      title="Enter a link to your clinic logo formatted in the proportion of 250px width by 100px height. Clinic logo should be less than 200kB in size. Acceptable image formats are .png, .jpg, and .gif The address clinic logo, if entered, will appear in exported PDFs"
                                      arrow
                                    >
                                      <HelpIcon style={{ cursor: "pointer" }} />
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="clinic-add-info"
                              label="Additional Clinic Info"
                              value={userSettings.clinicInfo}
                              onChange={(e) =>
                                setUserSettings({
                                  ...userSettings,
                                  clinicInfo: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />
                          </form>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    {/* --------------------------------------------------- THIRD -------------------------------------------------- */}

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="app__mainBody__list__header"
                        style={{ backgroundColor: "#0c27c8" }}
                      >
                        <Typography className={accordionClasses.heading}>
                          <strong>Encounter Settings:</strong>{" "}
                          <small>
                            {encounterInfo.dateTime && (
                              <span>
                                <i>Date:</i>{" "}
                                {new Date(
                                  encounterInfo.dateTime
                                ).toDateString()}{" "}
                                @ {getTime(encounterInfo.dateTime)}
                              </span>
                            )}{" "}
                            <br />
                            {encounterInfo.notes && (
                              <span>
                                <i>Encounter notes:</i> {encounterInfo.notes}
                              </span>
                            )}
                          </small>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="app__mainBody__list__body">
                        <Typography style={{ width: "100%" }}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid
                              container
                              justify="space-around"
                              style={{ marginBottom: "8px" }}
                            >
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date"
                                format="yyyy/MM/DD"
                                placeholder="2018/10/10"
                                value={encounterInfo.dateTime}
                                onChange={(date) =>
                                  setEncounterInfo({
                                    ...encounterInfo,
                                    dateTime: date,
                                  })
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                style={{ width: "45%" }}
                              />
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time"
                                placeholder="08:00 AM"
                                mask="__:__ _M"
                                value={encounterInfo.dateTime}
                                onChange={(date) =>
                                  setEncounterInfo({
                                    ...encounterInfo,
                                    dateTime: date,
                                  })
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                                style={{ width: "45%" }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>

                          <form
                            className={textfieldClasses.root}
                            noValidate
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <TextField
                              id="encounter-notes"
                              label="Encounter Notes"
                              value={encounterInfo.notes}
                              onChange={(e) =>
                                setEncounterInfo({
                                  ...encounterInfo,
                                  notes: e.target.value,
                                })
                              }
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="encounter-session-id"
                              label="Encounter Session ID"
                              value={encounterInfo.sessionID}
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ width: "96%" }}
                            />

                            <TextField
                              id="user-ip-address"
                              label="User IP Address"
                              value={encounterInfo.IPaddress}
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ width: "96%" }}
                            />
                          </form>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div className="app__mainBody__list__accordion">
                    <ReactSortable
                      list={orderOfList}
                      setList={setOrderOfList}
                      animation={200}
                      delayOnTouchStart={true}
                      delay={2}
                      onEnd={() => {}}
                      handle=".sorting-handle"
                    >
                      {orderOfList.map(({ typeOfList, indexOfItem }) => {
                        switch (typeOfList) {
                          case "ordered":
                            return (
                              <div
                                key={"orderedlist"}
                                className={`${accordionClasses.root} app__mainBody__list__accordion__list app__mainBody__list__accordion__ordered`}
                              >
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="app__mainBody__list__header"
                                    style={{ backgroundColor: "#0c27c8" }}
                                  >
                                    <Colors
                                      color={orderedListColor}
                                      onColorChange={(newColor) => {
                                        setOrderedListColor(newColor);
                                      }}
                                    />
                                    <Typography
                                      className={accordionClasses.heading}
                                    >
                                      Ordered Procedure (
                                      {orderedListContents.length})
                                    </Typography>
                                    <Reorder
                                      style={{
                                        cursor: "move",
                                        color: "#707070",
                                        position: "absolute",
                                        top: "50%",
                                        right: "40px",
                                        transform: "translateY(-50%)",
                                      }}
                                      className="sorting-handle"
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails className="app__mainBody__list__body">
                                    <Typography style={{ width: "100%" }}>
                                      <div
                                        className={`list__reorder ${
                                          listOrderDescending &&
                                          "descendingList"
                                        } ${
                                          orderedListContents.length === 0 &&
                                          "emptyList"
                                        }`}
                                        onClick={handleListOrdering}
                                      >
                                        <SortByAlphaIcon />
                                      </div>
                                      <ReactSortable
                                        list={orderedListContents}
                                        setList={setOrderedListContents}
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        onEnd={() => {
                                          setOrderedListContents(
                                            sortList(orderedListContents)
                                          );
                                          setAllSNS({ ...allSNS });
                                        }}
                                        handle=".item-handle-icon"
                                      >
                                        {orderedListContents.map(
                                          (listContent) => (
                                            <ListContent
                                              id={listContent.id}
                                              biopsy__type={
                                                listContent.biopsy__type
                                              }
                                              anatomic__site={
                                                listContent.anatomic__site
                                              }
                                              list__type={
                                                listContent.list__type
                                              }
                                              hierarchy={listContent.hierarchy}
                                              user__notes={
                                                listContent.user__notes
                                              }
                                              user__image={
                                                listContent.user__image
                                              }
                                              ar={
                                                arNames[
                                                  listContent.droppedPin__id
                                                ]
                                              }
                                              setAnatomicSite={(
                                                newAnatomicSite
                                              ) => {
                                                let newOrderedListContents = [
                                                  ...orderedListContents,
                                                ];

                                                newOrderedListContents.splice(
                                                  newOrderedListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    anatomic__site:
                                                      newAnatomicSite,
                                                  }
                                                );

                                                setOrderedListContents(
                                                  newOrderedListContents
                                                );
                                              }}
                                              setBiopsy__type={(biopsy) => {
                                                let newOrderedListContents = [
                                                  ...orderedListContents,
                                                ];

                                                newOrderedListContents.splice(
                                                  newOrderedListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    biopsy__type: biopsy,
                                                  }
                                                );

                                                setOrderedListContents(
                                                  newOrderedListContents
                                                );
                                              }}
                                              delete__content={(id) => {
                                                let newOrderedListContents = [
                                                  ...orderedListContents,
                                                ];

                                                let deletedItem = {};

                                                newOrderedListContents =
                                                  sortList(
                                                    newOrderedListContents.filter(
                                                      (content) => {
                                                        if (content.id === id) {
                                                          deletedItem = content;
                                                        }
                                                        return (
                                                          content.id !== id
                                                        );
                                                      }
                                                    )
                                                  );

                                                addToThrashBin(
                                                  deletedItem,
                                                  "ordered"
                                                );
                                                if (
                                                  newOrderedListContents.length ===
                                                  0
                                                ) {
                                                  deleteOrderOfList("ordered");
                                                }
                                                setOrderedListContents(
                                                  newOrderedListContents
                                                );
                                              }}
                                              setUser__image={(image) => {
                                                let newOrderedListContents = [
                                                  ...orderedListContents,
                                                ];
                                                newOrderedListContents.splice(
                                                  newOrderedListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__image: image,
                                                  }
                                                );

                                                setOrderedListContents(
                                                  newOrderedListContents
                                                );
                                              }}
                                              setUser__notes={(notes) => {
                                                let newOrderedListContents = [
                                                  ...orderedListContents,
                                                ];

                                                newOrderedListContents.splice(
                                                  newOrderedListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__notes: notes,
                                                  }
                                                );

                                                setOrderedListContents(
                                                  newOrderedListContents
                                                );
                                              }}
                                              transfer_to_parent={(
                                                parentId
                                              ) => {
                                                transferPoints(
                                                  parentId,
                                                  listContent.droppedPin__id,
                                                  "ordered"
                                                );
                                              }}
                                              droppedPinId={
                                                listContent.droppedPin__id
                                              }
                                              onLocatePin={onLocatePin}
                                              attrInfo={listContent.attrInfo}
                                              sns={
                                                allSNS[
                                                  listContent.droppedPin__id
                                                ] || globalSNS
                                              }
                                              native_name={
                                                listContent.native_name
                                              }
                                              defined_name={
                                                listContent.defined_name
                                              }
                                              onOpenNameBuilder={() => {
                                                dispatchNBActions({
                                                  type: "open",
                                                  names: {
                                                    ...listContent.defined_name,
                                                    auto_related_name:
                                                      arNames[
                                                        listContent
                                                          .droppedPin__id
                                                      ] ?? [],
                                                  },
                                                  listContentInfo: {
                                                    listType: "ordered",
                                                    index: null,
                                                    droppedPin__id:
                                                      listContent.droppedPin__id,
                                                    id: listContent.id,
                                                    listSubType: null,
                                                  },
                                                  defaults: {
                                                    laterality:
                                                      listContent.native_name
                                                        .laterality,
                                                  },
                                                  sns: patchSNSObject(
                                                    allSNS[
                                                      listContent.droppedPin__id
                                                    ] || globalSNS,
                                                    listContent.attrInfo.amid
                                                  ),
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </ReactSortable>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                          case "comments":
                            return (
                              <div
                                key={"commentslist"}
                                className={`${accordionClasses.root} app__mainBody__list__accordion__list app__mainBody__list__accordion__comments`}
                              >
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="app__mainBody__list__header"
                                    style={{ backgroundColor: "#0c27c8" }}
                                  >
                                    <Colors
                                      color={commentListColor}
                                      onColorChange={(newColor) => {
                                        setCommentListColor(newColor);
                                      }}
                                    />

                                    <Typography
                                      className={accordionClasses.heading}
                                    >
                                      Comments List (
                                      {commentListContents.length})
                                    </Typography>
                                    <Reorder
                                      style={{
                                        cursor: "move",
                                        color: "#707070",
                                        position: "absolute",
                                        top: "50%",
                                        right: "40px",
                                        transform: "translateY(-50%)",
                                      }}
                                      className="sorting-handle"
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails className="app__mainBody__list__body">
                                    <Typography
                                      component={"div"}
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className={`list__reorder ${
                                          listOrderDescending &&
                                          "descendingList"
                                        } ${
                                          commentListContents.length === 0 &&
                                          "emptyList"
                                        }`}
                                        onClick={handleListOrdering}
                                      >
                                        <SortByAlphaIcon />
                                      </div>
                                      <ReactSortable
                                        list={commentListContents}
                                        setList={setCommentListContents}
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        onEnd={() => {
                                          setCommentListContents(
                                            sortList(commentListContents)
                                          );
                                        }}
                                        handle=".item-handle-icon"
                                      >
                                        {commentListContents.map(
                                          (listContent) => (
                                            <ListContent
                                              color={commentListColor}
                                              id={listContent.id}
                                              biopsy__type={
                                                listContent.biopsy__type
                                              }
                                              anatomic__site={
                                                listContent.anatomic__site
                                              }
                                              list__type={
                                                listContent.list__type
                                              }
                                              hierarchy={listContent.hierarchy}
                                              ar={
                                                arNames[
                                                  listContent.droppedPin__id
                                                ]
                                              }
                                              user__notes={
                                                listContent.user__notes
                                              }
                                              user__image={
                                                listContent.user__image
                                              }
                                              user__comment={
                                                listContent.user__comment
                                              }
                                              setAnatomicSite={(
                                                newAnatomicSite
                                              ) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];

                                                newCommentListContents.splice(
                                                  newCommentListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    anatomic__site:
                                                      newAnatomicSite,
                                                  }
                                                );

                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              setBiopsy__type={(biopsy) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];

                                                newCommentListContents.splice(
                                                  newCommentListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    biopsy__type: biopsy,
                                                  }
                                                );

                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              delete__content={(id) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];
                                                let deletedItem = {};
                                                newCommentListContents =
                                                  sortList(
                                                    newCommentListContents.filter(
                                                      (content) => {
                                                        if (content.id === id) {
                                                          deletedItem = content;
                                                        }
                                                        return (
                                                          content.id !== id
                                                        );
                                                      }
                                                    )
                                                  );
                                                addToThrashBin(
                                                  deletedItem,
                                                  "comments"
                                                );
                                                if (
                                                  newCommentListContents.length ===
                                                  0
                                                ) {
                                                  deleteOrderOfList("comments");
                                                }
                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              setUser__image={(image) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];
                                                newCommentListContents.splice(
                                                  newCommentListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__image: image,
                                                  }
                                                );

                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              setUser__notes={(notes) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];

                                                newCommentListContents.splice(
                                                  newCommentListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__notes: notes,
                                                  }
                                                );

                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              setUser__comments={(comments) => {
                                                let newCommentListContents = [
                                                  ...commentListContents,
                                                ];

                                                newCommentListContents.splice(
                                                  newCommentListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__comment: comments,
                                                  }
                                                );

                                                setCommentListContents(
                                                  newCommentListContents
                                                );
                                              }}
                                              transfer_to_parent={(
                                                parentId
                                              ) => {
                                                transferPoints(
                                                  parentId,
                                                  listContent.droppedPin__id,
                                                  "comments"
                                                );
                                              }}
                                              coords={listContent.coords}
                                              droppedPinId={
                                                listContent.droppedPin__id
                                              }
                                              onLocatePin={onLocatePin}
                                              attrInfo={listContent.attrInfo}
                                              native_name={
                                                listContent.native_name
                                              }
                                              defined_name={
                                                listContent.defined_name
                                              }
                                              sns={
                                                allSNS[
                                                  listContent.droppedPin__id
                                                ] || globalSNS
                                              }
                                              onOpenNameBuilder={() => {
                                                dispatchNBActions({
                                                  type: "open",
                                                  names: {
                                                    ...listContent.defined_name,
                                                    auto_related_name:
                                                      arNames[
                                                        listContent
                                                          .droppedPin__id
                                                      ] ?? [],
                                                  },
                                                  listContentInfo: {
                                                    listType: "comments",
                                                    droppedPin__id:
                                                      listContent.droppedPin__id,

                                                    index: null,
                                                    id: listContent.id,
                                                    listSubType: null,
                                                  },
                                                  defaults: {
                                                    laterality:
                                                      listContent.native_name
                                                        .laterality,
                                                  },
                                                  sns: patchSNSObject(
                                                    allSNS[
                                                      listContent.droppedPin__id
                                                    ] || globalSNS,
                                                    listContent.attrInfo.amid
                                                  ),
                                                });
                                              }}
                                            />
                                          )
                                        )}
                                      </ReactSortable>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                          case "diagnosis":
                            return (
                              <div
                                key="diagnosis"
                                className={`${accordionClasses.root} app__mainBody__list__accordion__list app__mainBody__list__accordion__comments`}
                              >
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="app__mainBody__list__header"
                                    style={{ backgroundColor: "#0c27c8" }}
                                  >
                                    <Colors
                                      color={diagnosisListColor}
                                      onColorChange={(newColor) => {
                                        setDiagnosisListColor(newColor);
                                      }}
                                    />

                                    <Typography
                                      className={accordionClasses.heading}
                                    >
                                      Diagnosis List (
                                      {diagnosisListContents.length})
                                    </Typography>
                                    <Reorder
                                      style={{
                                        cursor: "move",
                                        color: "#707070",
                                        position: "absolute",
                                        top: "50%",
                                        right: "40px",
                                        transform: "translateY(-50%)",
                                      }}
                                      className="sorting-handle"
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails className="app__mainBody__list__body">
                                    <Typography
                                      component={"div"}
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className={`list__reorder ${
                                          listOrderDescending &&
                                          "descendingList"
                                        } ${
                                          commentListContents.length === 0 &&
                                          "emptyList"
                                        }`}
                                        onClick={handleListOrdering}
                                      >
                                        <SortByAlphaIcon />
                                      </div>
                                      <ReactSortable
                                        list={diagnosisListContents}
                                        setList={setDiagnosisListContents}
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        onEnd={() => {
                                          setDiagnosisListContents(
                                            sortList(diagnosisListContents)
                                          );
                                        }}
                                        handle=".item-handle-icon"
                                      >
                                        {diagnosisListContents.map(
                                          (listContent) => (
                                            <ListContent
                                              id={listContent.id}
                                              color={diagnosisListColor}
                                              biopsy__type={
                                                listContent.biopsy__type
                                              }
                                              ar={
                                                arNames[
                                                  listContent.droppedPin__id
                                                ]
                                              }
                                              anatomic__site={
                                                listContent.anatomic__site
                                              }
                                              list__type={
                                                listContent.list__type
                                              }
                                              hierarchy={listContent.hierarchy}
                                              user__notes={
                                                listContent.user__notes
                                              }
                                              user__image={
                                                listContent.user__image
                                              }
                                              user__comment={
                                                listContent.user__comment
                                              }
                                              setAnatomicSite={(
                                                newAnatomicSite
                                              ) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];

                                                newDiagnosisListContents.splice(
                                                  newDiagnosisListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    anatomic__site:
                                                      newAnatomicSite,
                                                  }
                                                );

                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              setBiopsy__type={(biopsy) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];

                                                newDiagnosisListContents.splice(
                                                  newDiagnosisListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    biopsy__type: biopsy,
                                                  }
                                                );

                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              delete__content={(id) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];
                                                let deletedItem = {};
                                                newDiagnosisListContents =
                                                  sortList(
                                                    newDiagnosisListContents.filter(
                                                      (content) => {
                                                        if (content.id === id) {
                                                          deletedItem = content;
                                                        }
                                                        return (
                                                          content.id !== id
                                                        );
                                                      }
                                                    )
                                                  );
                                                addToThrashBin(
                                                  deletedItem,
                                                  "diagnosis"
                                                );
                                                if (
                                                  newDiagnosisListContents.length ===
                                                  0
                                                ) {
                                                  deleteOrderOfList(
                                                    "diagnosis"
                                                  );
                                                }
                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              setUser__image={(image) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];
                                                newDiagnosisListContents.splice(
                                                  newDiagnosisListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__image: image,
                                                  }
                                                );

                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              setUser__notes={(notes) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];

                                                newDiagnosisListContents.splice(
                                                  newDiagnosisListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__notes: notes,
                                                  }
                                                );

                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              setUser__comments={(comments) => {
                                                let newDiagnosisListContents = [
                                                  ...diagnosisListContents,
                                                ];

                                                newDiagnosisListContents.splice(
                                                  newDiagnosisListContents.findIndex(
                                                    (content) =>
                                                      content.id ===
                                                      listContent.id
                                                  ),
                                                  1,
                                                  {
                                                    ...listContent,
                                                    user__comment: comments,
                                                  }
                                                );

                                                setDiagnosisListContents(
                                                  newDiagnosisListContents
                                                );
                                              }}
                                              transfer_to_parent={(
                                                parentId
                                              ) => {
                                                // console.log(
                                                //   parentId,
                                                //   listContent.droppedPin__id
                                                // );
                                                transferPoints(
                                                  parentId,
                                                  listContent.droppedPin__id,
                                                  "diagnosis"
                                                );
                                              }}
                                              coords={listContent.coords}
                                              droppedPinId={
                                                listContent.droppedPin__id
                                              }
                                              onLocatePin={onLocatePin}
                                              attrInfo={listContent.attrInfo}
                                              native_name={
                                                listContent.native_name
                                              }
                                              defined_name={
                                                listContent.defined_name
                                              }
                                              onOpenNameBuilder={() => {
                                                dispatchNBActions({
                                                  type: "open",
                                                  names: {
                                                    ...listContent.defined_name,
                                                    auto_related_name:
                                                      arNames[
                                                        listContent
                                                          .droppedPin__id
                                                      ] ?? [],
                                                  },
                                                  listContentInfo: {
                                                    listType: "diagnosis",
                                                    droppedPin__id:
                                                      listContent.droppedPin__id,

                                                    index: null,
                                                    id: listContent.id,
                                                    listSubType: null,
                                                  },
                                                  defaults: {
                                                    laterality:
                                                      listContent.native_name
                                                        .laterality,
                                                  },
                                                  sns: patchSNSObject(
                                                    allSNS[
                                                      listContent.droppedPin__id
                                                    ] || globalSNS,
                                                    listContent.attrInfo.amid
                                                  ),
                                                });
                                              }}
                                              sns={
                                                allSNS[
                                                  listContent.droppedPin__id
                                                ] || globalSNS
                                              }
                                            />
                                          )
                                        )}
                                      </ReactSortable>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                          case "grouped":
                            const index = indexOfItem;
                            const g = groupedList[index];
                            const listLabelAndVal =
                              getTotalForGroupedList(index);
                            return (
                              <div
                                key={"grouped" + index}
                                className={`${accordionClasses.root}   app__mainBody__list__accordion__list app__mainBody__list__accordion__unordered`}
                              >
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="app__mainBody__list__header"
                                    style={{
                                      backgroundColor: "#0c27c8",
                                      position: "relative",
                                    }}
                                  >
                                    <Colors
                                      color={g.color}
                                      onColorChange={(newColor) => {
                                        const newGrpList = [...groupedList];
                                        newGrpList[index].color = newColor;
                                        setGroupedList(newGrpList);
                                      }}
                                    />

                                    <Typography
                                      className={accordionClasses.heading}
                                    >
                                      {g.name} ({listLabelAndVal.label} :{" "}
                                      {listLabelAndVal.total})
                                    </Typography>
                                    <Reorder
                                      style={{
                                        cursor: "move",
                                        color: "#707070",
                                        position: "absolute",
                                        top: "50%",
                                        right: "40px",
                                        transform: "translateY(-50%)",
                                      }}
                                      className="sorting-handle"
                                    />
                                  </AccordionSummary>

                                  <AdditionalDocumentation
                                    name={g.name}
                                    note={g.note}
                                    code={g.code}
                                    images={g.images}
                                    inputs={g.inputs}
                                    addDocs={g.addDocs}
                                    onNoteChange={(newNote) => {
                                      const newGList = [...groupedList];
                                      newGList[index] = {
                                        ...newGList[index],
                                        note: newNote,
                                      };
                                      setGroupedList(newGList);
                                    }}
                                    onImagesChange={(images) => {
                                      const newGList = [...groupedList];
                                      newGList[index] = {
                                        ...newGList[index],
                                        images: images,
                                      };
                                      setGroupedList(newGList);
                                    }}
                                    onInputChange={(newValue, ind) => {
                                      const newGList = [...groupedList];
                                      newGList[index] = {
                                        ...newGList[index],
                                        inputs: [...newGList[index].inputs],
                                      };
                                      newGList[index].inputs[ind] = {
                                        ...newGList[index].inputs[ind],
                                        value: newValue,
                                      };
                                      setGroupedList(newGList);
                                    }}
                                    onCodeChange={(newValue) => {
                                      const newGList = [...groupedList];
                                      newGList[index] = {
                                        ...newGList[index],
                                        code: {
                                          ...newGList[index].code,
                                          value: newValue,
                                        },
                                      };
                                      setGroupedList(newGList);
                                    }}
                                  />
                                  <GroupedItems
                                    listItems={g.items}
                                    color={g.color}
                                    name={g.name}
                                    getARName={(id) => arNames[id]}
                                    setAnatomicSiteGroup={(
                                      newAnatomicSite,
                                      ind
                                    ) => {
                                      // console.log(newAnatomicSite);
                                      const newGroupedList =
                                        cloneDeep(groupedList);
                                      // console.log('Before',newGroupedList);
                                      let [newItem] = newGroupedList[
                                        index
                                      ].items.splice(ind, 1);

                                      newGroupedList[index].items.splice(
                                        ind,
                                        1,
                                        {
                                          ...newItem,
                                          anatomic__site: newAnatomicSite,
                                        }
                                      );
                                      // console.log(newGroupedList);
                                      // console.log(newItem);

                                      setGroupedList(newGroupedList);
                                    }}
                                    setBiopsy__type={(newType, ind) => {
                                      const newGroupedList =
                                        cloneDeep(groupedList);
                                      // console.log('Before',newGroupedList);
                                      const [newItem] = newGroupedList[
                                        index
                                      ].items.splice(ind, 1);
                                      // console.log(newGroupedList);
                                      if (
                                        newGroupedList[index].items.length === 0
                                      ) {
                                        newGroupedList.splice(index, 1);
                                      }
                                      const i = newGroupedList.findIndex(
                                        (value) => value.name === newType
                                      );
                                      if (i === -1) {
                                        newGroupedList.push({
                                          ...groupAttributes[newType],
                                          id: newType,
                                          items: [
                                            {
                                              ...newItem,
                                              biopsy__type: newType,
                                            },
                                          ],
                                        });
                                      } else {
                                        newGroupedList[i].items.push({
                                          ...newItem,
                                          biopsy__type: newType,
                                        });
                                      }
                                      setGroupedList(newGroupedList);
                                    }}
                                    delete__content={(ind) => {
                                      const newGroupedList =
                                        cloneDeep(groupedList);
                                      const [item] = newGroupedList[
                                        index
                                      ].items.splice(ind, 1);

                                      addToThrashBin(
                                        item,
                                        "grouped",
                                        newGroupedList[index].name
                                      );
                                      if (
                                        newGroupedList[index].items.length === 0
                                      ) {
                                        // if(newOrderedListContents.length===0){
                                        // console.log(
                                        //   "Deleting Order Of List",
                                        //   index,
                                        //   "grouped"
                                        // );
                                        deleteOrderOfList("grouped", index);
                                        // }
                                        newGroupedList.splice(index, 1);
                                      }
                                      setGroupedList(newGroupedList);
                                    }}
                                    setUser__image={(ind, images) => {
                                      const list = cloneDeep(groupedList);
                                      list[index].items[ind] = {
                                        ...list[index].items[ind],
                                        user__image: images,
                                      };
                                      setGroupedList(list);
                                    }}
                                    setListItems={(newList) => {
                                      const list = [...groupedList];
                                      list[index] = {
                                        ...list[index],
                                        items: newList,
                                      };
                                      setGroupedList(list);
                                    }}
                                    onInputChange={(
                                      newValue,
                                      itemIndex,
                                      ind
                                    ) => {
                                      const newGrpList = cloneDeep(groupedList);
                                      newGrpList[index].items[itemIndex].inputs[
                                        ind
                                      ]["value"] = newValue;
                                      setGroupedList(newGrpList);
                                    }}
                                    transfer_to_parent={(parentId, id) => {
                                      transferPoints(
                                        parentId,
                                        id,
                                        "grouped",
                                        index
                                      );
                                    }}
                                    onLocatePin={onLocatePin}
                                    onOpenNameBuilder={({
                                      names,
                                      sns,
                                      id,
                                      native_name,
                                      droppedPin__id,
                                    }) => {
                                      dispatchNBActions({
                                        type: "open",
                                        names: {
                                          ...names,
                                          auto_related_name:
                                            arNames[droppedPin__id] ?? [],
                                        },
                                        listContentInfo: {
                                          listType: "grouped",
                                          droppedPin__id,
                                          index: null,
                                          id,
                                          listSubType: g.name,
                                        },
                                        defaults: {
                                          laterality: native_name.laterality,
                                        },
                                        sns:
                                          allSNS[droppedPin__id] || globalSNS,
                                      });
                                    }}
                                    sns={globalSNS}
                                  />
                                </Accordion>
                              </div>
                            );
                          default:
                            console.log("In Here");
                            return null;
                        }
                      })}
                    </ReactSortable>

                    {deleteList.length !== 0 && (
                      <div
                        className={`${accordionClasses.root} app__mainBody__list__accordion__list app__mainBody__list__accordion__comments`}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="app__mainBody__list__header"
                            style={{ backgroundColor: "#f44336" }}
                          >
                            <Typography className={accordionClasses.heading}>
                              Unlisted items ({deleteList.length})
                            </Typography>
                            <Tooltip
                              title={
                                "These pins and their content may be restored to the list they originated from; moved to a different list; or deleted completely. Images and notes may be recycled to an exisiting list item. Any content in this area will not be exported."
                              }
                            >
                              <IconButton style={{ padding: "0px 12px" }}>
                                <Help />
                              </IconButton>
                            </Tooltip>
                          </AccordionSummary>
                          <AccordionDetails className="app__mainBody__list__body">
                            <DeleteListContent
                              onUndoItem={(index) => {
                                const { item, listType } = deleteList[index];
                                console.log(item, listType);
                                const newDeleteList = cloneDeep(deleteList);
                                newDeleteList.splice(index, 1);
                                setDeleteList(newDeleteList);
                                document.getElementById(item.droppedPin__id) &&
                                  (document.getElementById(
                                    item.droppedPin__id
                                  ).style.display = "inline");
                                updateList(item, listType, true);
                              }}
                              list={deleteList}
                              onDeleteItem={(index) => {
                                const newDeleteList = cloneDeep(deleteList);
                                const [item] = newDeleteList.splice(index, 1);
                                document.getElementById(item.droppedPin__id) &&
                                  document
                                    .getElementById(item.droppedPin__id)
                                    .remove();

                                setDeleteList(newDeleteList);
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </div>
                </div>

                {/* Dialogs */}

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {"Clear List Contents?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Are you sure you want to clear all the list contents? This
                      action cannot be undone.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      No
                    </Button>
                    <Button onClick={handleClearList} color="primary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </List.Provider>
          <div
            ref={exportedListRef}
            className={`app__mainBody__exportList ${
              showListContents && "showList"
            }`}
          >
            <ExportList
              orderedListContents={orderedListContents}
              unorderedListContents={unorderedListContents}
            />

            <Preview
              orderedListContents={orderedListContents}
              unorderedListContents={unorderedListContents}
              img={img}
              download={download}
            />
          </div>
        </div>
      )}

      <TermsAndConditions
        {...{ setOpenlt, openlt }}
        handleReject={() => {
          setOpenlt(false);
          setIsNotAgreed({
            open: true,
            isPrivacy: false,
          });
        }}
        handleAccept={() => {
          setUserSettings((old) => ({
            ...old,
            acceptLic: true,
          }));
          setOpenlt(false);
        }}
      />

      <PrivacyPolicy
        {...{ setOpenpp, openpp }}
        handleReject={() => {
          setOpenpp(false);
          setIsNotAgreed({
            open: true,
            isPrivacy: true,
          });
        }}
        handleAccept={() => {
          setUserSettings((old) => ({
            ...userSettings,
            acceptStatement: true,
          }));
          setOpenpp(false);
        }}
      />

      <DisablePopup
        {...isNotAgreed}
        handleClose={() => {
          if (isNotAgreed.isPrivacy) {
            setOpenpp(true);
          } else {
            setOpenlt(true);
          }
          setIsNotAgreed({
            open: false,
            isPrivacy: true,
          });
        }}
      />

      <BackdropForDownload open={loader} />
    </>
  );
}

export default App;

export { List };
