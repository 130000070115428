import React,{  useState,useRef, useEffect } from 'react';
import Typography from "@material-ui/core/Typography";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

export default function PrivacyPolicy({ setOpenpp, openpp, handleAccept,handleReject }) {
  
  const [isEnable, setIsEnabled] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = (e) => {
    if (isEnable) return;
    const bottom = Math.round((e.target.scrollHeight - e.target.scrollTop)) === e.target.clientHeight;
    setIsEnabled(bottom);
  };

  
  const handleEnablingScroll = (ref)=>{
  
    if(ref){
      if(ref.scrollTop===0){
        ref.scrollTop = 1;
        console.log(ref.scrollTop);
        if(ref.scrollTop === 0){
          setIsEnabled(true);
        }else{
          setIsEnabled(false);
        }
      }
    }

    contentRef.current = ref;
  }

  useEffect(()=>{
    const handleEnablingScrollUsingRef = ()=>{
      if(contentRef.current){
        if(contentRef.current.scrollTop===0){
          contentRef.current.scrollTop = 1;
          console.log(contentRef.current.scrollTop);
          if(contentRef.current.scrollTop === 0){
            setIsEnabled(true);
          }else{
            setIsEnabled(false);
          }
        }
      }
    }
    window.addEventListener('resize',handleEnablingScrollUsingRef);
    return ()=>{
      window.removeEventListener('resize',handleEnablingScrollUsingRef);
    }
  },[]);
  

  return (
    <Dialog
    onClose={() => setOpenpp(false)}
    aria-labelledby="term-dialog-title"
      open={openpp}
      disableBackdropClick
  >
    <DialogTitle id="term-dialog-title" onClose={() => setOpenpp(false)}>
      Privacy Policy
    </DialogTitle>
    <DialogContent ref={handleEnablingScroll}    onScroll={handleScroll}  dividers>
      <Typography gutterBottom>
        Cras mattis consectetur purus sit amet fermentum. Cras justo
        odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
        risus, porta ac consectetur ac, vestibulum at eros.
      </Typography>
      <Typography gutterBottom>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur
        et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus
        dolor auctor.
      </Typography>
      <Typography gutterBottom>
        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
        cursus magna, vel scelerisque nisl consectetur et. Donec sed
        odio dui. Donec ullamcorper nulla non metus auctor fringilla.
        </Typography>
        <Typography gutterBottom>
        Cras mattis consectetur purus sit amet fermentum. Cras justo
        odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
        risus, porta ac consectetur ac, vestibulum at eros.
      </Typography>
      <Typography gutterBottom>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur
        et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus
        dolor auctor.
      </Typography>
      <Typography gutterBottom>
        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
        cursus magna, vel scelerisque nisl consectetur et. Donec sed
        odio dui. Donec ullamcorper nulla non metus auctor fringilla.
        </Typography>
        <Typography gutterBottom>
        Cras mattis consectetur purus sit amet fermentum. Cras justo
        odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
        risus, porta ac consectetur ac, vestibulum at eros.
      </Typography>
      <Typography gutterBottom>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur
        et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus
        dolor auctor.
      </Typography>
      <Typography gutterBottom>
        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
        cursus magna, vel scelerisque nisl consectetur et. Donec sed
        odio dui. Donec ullamcorper nulla non metus auctor fringilla.
      </Typography><Typography gutterBottom>
        Cras mattis consectetur purus sit amet fermentum. Cras justo
        odio, dapibus ac facilisis in, egestas eget quam. Morbi leo
        risus, porta ac consectetur ac, vestibulum at eros.
      </Typography>
      <Typography gutterBottom>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur
        et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus
        dolor auctor.
      </Typography>
      <Typography gutterBottom>
        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
        cursus magna, vel scelerisque nisl consectetur et. Donec sed
        odio dui. Donec ullamcorper nulla non metus auctor fringilla.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={handleReject}
      >
        Decline
      </Button>
        <Button
          disabled={isEnable=== false}
          color="primary"
          onClick={handleAccept}
      >
        I accept
      </Button>
    </DialogActions>
  </Dialog>

  )
}
