import { makeStyles } from "@material-ui/styles";
import NameRenderer from "../NameRenderer";
import { innerLayerColors } from ".";
import { Box, Popper } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  activePath: {
    fill: "rgb(141, 235, 137) !important",
    opacity: "1 !important",
    zIndex: 1300,
  },
  activePathHierarchy: {
    fill: "rgba(255, 0, 0, 0.7) !important",
    opacity: "1 !important",
    zIndex: 1301,
  },
  label: {
    background: "red",
    margin: "1vw",
    borderRadius: "50%",
  },
  container: {
    borderRadius: "10px",
    background: "rgba(255,255,255,.7)",
    zIndex: 1300,
    padding: "10px",
    boxShadow: "0 2px 2px rgba(0,0,0,.2)",
    overflow: "auto",
    position: "fixed",
    border: "1px solid #999",
    bottom: 120,
    left: 10,
    transition: "all .4s",
  },
}));

const Circle = ({ size }) => {
  return (
    <span
      style={{
        borderRadius: "50%",
        height: size,
        width: size,
        backgroundColor: "currentcolor",
        display: "block",
      }}
    ></span>
  );
};

// const EGZInfo = (props) => {
//   return (
//     <span style={{ fontSize: 11 }}>
//       (<span>EGZT: {String(Boolean(props.egz.egzt))}</span>
//       {Boolean(props.egz.egzt) && (
//         <span>
//           , EGZX: {props.egz.egzx}, EGZY: {props.egz.egzy}
//         </span>
//       )}
//       <span>
//         , Deviation x: {Math.round(props.deviation.dev_x)} y:{" "}
//         {Math.round(props.deviation.dev_y)}
//       </span>
//       )
//     </span>
//   );
// };
export default function SVGHoverlay({
  innerLayers,
  hoverMode,
  activePathId,
  sns,
  activeEGZ,
  activeHoverElement,
}) {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{ opacity: activePathId ? 1 : 0 }}
    >
      <div style={{ marginBottom: 8 }}>
        <span
          style={{
            color:
              hoverMode === "standalone"
                ? "rgb(141, 235, 137)"
                : "rgb(255, 0,0)",
            fontSize: 14,
            display: "flex",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Circle size={14} />{" "}
          {activePathId && (
            <NameRenderer noEdit sns={sns} {...activeEGZ.names} />
          )}
        </span>
      </div>
      <div style={{ fontSize: 12, display: "flex", flexDirection: "column" }}>
        {innerLayers.map((id, index) => {
          return (
            <span
              style={{
                color: innerLayerColors[index].color,
                backgroundColor: innerLayerColors[index].bg,
                display: "flex",
                gap: 4,
              }}
            >
              <Circle size={12} />{" "}
              <NameRenderer noEdit sns={sns} {...id.egz.names} />
              {/* <EGZInfo egz={id.egz.egz} deviation={id.egz.deviation} /> */}
            </span>
          );
        })}
      </div>
      <Popper
        id={"popper-hover-svg"}
        open={Boolean(activeHoverElement)}
        style={{ zIndex: 1600 }}
        anchorEl={activeHoverElement}
        placement="bottom"
        keepMounted={false}
      >
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,.6)",
            color: "#fff",
            borderRadius: 8,
            border: "1px solid #eee",
            padding: 8,
            boxShadow: "2px 2px 2px rgba(0,0,0,.2)",
          }}
        >
          <NameRenderer {...activeEGZ?.names} sns={sns} noEdit />
        </Box>
      </Popper>
    </div>
  );
}
