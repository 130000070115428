import React, { useState, useContext } from "react";
import { List } from "../../App";
import LoadSVG from "../LoadSVG";
import { genRandomString, getEGZInfo } from "../../utils/cf";
import SVGHoverlay from "./SVGHoverlay";

const SCALE = 4;

var ua = navigator.userAgent.toLowerCase();
let isSafari = false;
if (ua.indexOf("safari") !== -1) {
  if (!(ua.indexOf("chrome") !== -1)) {
    isSafari = true;
  }
}

const getClassForMarker = (listType, listTT) => {
  return `Marker-${listType}-${listTT}`;
};

export const getParentsAndChildren = (pathId, coords) => {
  let hierarchy = [];
  // let v_children = [];
  // const boundingRect = document.getElementById(pathId).getBoundingClientRect();
  const parentEl = document.getElementById(pathId)?.parentElement;
  if (parentEl) {
    // const children = parentEl.children;
    const elms = [];

    let elm;
    while (true) {
      elm = document.elementFromPoint(coords.x, coords.y);
      if (!parentEl.contains(elm)) {
        break;
      }
      const id = elm.getAttribute("id");
      if (id !== pathId) {
        hierarchy.push(id);
      }
      elms.push(elm);
      //Hide the element from the next round of `elementFromPoint()`:
      elm.style.pointerEvents = "none";
    }

    // output.textContent = elms.map(printElement).join(' ');

    //Cleanup:
    elms.forEach((elm) => (elm.style.pointerEvents = ""));
  }
  return hierarchy;
};

export const innerLayerColors = [
  { color: "#FFA500", bg: "#fff" },
  { color: "#ffff00", bg: "#000" },
  { color: "#00FF00", bg: "#fff" },
  { color: "#0000FF", bg: "#fff" },
  { color: "#4B0082", bg: "#fff" },
  { color: "#8F00FF", bg: "#fff" },
  { color: "#D3D3D3", bg: "#fff" },
  { color: "#A9A9A9", bg: "#fff" },
  { color: "#000000", bg: "#fff" },
  { color: "#FF0000", bg: "#fff" },
  { color: "#4B0082", bg: "#fff" },
  { color: "#FF7F00", bg: "#fff" },
];

let prevHierarchy = [];

const styleHierarchy = (hierarchy = []) => {
  prevHierarchy.forEach((id, index) => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.style.opacity = 0;
      ele.style.fill = undefined;
    }

    // document.getElementById(id).style.zIndex = undefined;
  });
  hierarchy.forEach((id, index) => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.style.opacity = 1;
      ele.style.fill = innerLayerColors[index].color;
    }

    // document.getElementById(id).style.zIndex = index + 1;
  });
  prevHierarchy = hierarchy;
};
let prevPathId = "";
let prevIsHierarchy = false;
const styleActivePath = (pathId, isHierarchy = false) => {
  if (prevPathId) {
    if (prevIsHierarchy) {
      document
        .getElementById(prevPathId)
        .classList.remove("active-path-x-hierarchy");
    } else {
      document.getElementById(prevPathId).classList.remove("active-path-x");
    }
  }
  if (pathId) {
    if (isHierarchy) {
      document.getElementById(pathId).classList.add("active-path-x-hierarchy");
    } else {
      document.getElementById(pathId).classList.add("active-path-x");
    }
  }

  prevPathId = pathId;
  prevIsHierarchy = isHierarchy;
};

export const generateId = (listType = "") => {
  const randomId = genRandomString(8);

  switch (listType) {
    case "ordered":
      return {
        id: randomId,
        droppedPin__id: `ordered__${randomId}`,
      };
    case "comments":
      return {
        id: randomId,
        droppedPin__id: `comments__${randomId}`,
      };
    case "diagnosis":
      return {
        id: randomId,
        droppedPin__id: `diagnosis__${randomId}`,
      };
    case "grouped":
    // eslint-disable-next-line no-fallthrough
    case "unordered": {
      return {
        id: randomId,
        droppedPin__id: `unordered__${randomId}`,
      };
    }
    default:
      return null;
  }
};

export const addMarker = (
  coords = { x: 0, y: 0 },
  counter = 0,
  zoom = 1,
  droppedPin__id = "",
  listType = "",
  subType = ""
) => {
  const rootSVG = document.querySelector("#loaded-svg-cont").firstChild;
  const NS = rootSVG.getAttribute("xmlns");
  const pt = rootSVG.createSVGPoint();
  pt.x = coords.x;
  pt.y = coords.y;
  console.log(coords);
  const svgP = pt.matrixTransform(rootSVG.getScreenCTM().inverse());
  const svg = document.createElementNS(NS, "svg");
  const g = document.createElementNS(NS, "g");
  const circle = document.createElementNS(NS, "circle");
  const desc = document.createElementNS(NS, "text");
  desc.classList.add("Pin-Description");
  const label = document.createElementNS(NS, "text");
  if (listType !== "comments" && listType !== "diagnosis") {
    label.setAttribute("x", -3 * SCALE);
    label.setAttribute("y", -3 * SCALE);
    label.classList.add("Pin-Label");
    if (listType === "ordered") {
      label.innerHTML = String.fromCharCode(65 + counter);
      label.style.fontSize = 0.6 * SCALE + "rem";
    }
  } else {
    if (counter <= 9) {
      label.setAttribute("x", -2.5 * SCALE);
      label.setAttribute("y", 2.75 * SCALE);
      label.style.fontSize = 0.55 * SCALE + "rem";
    } else {
      label.setAttribute("x", -3.25 * SCALE);
      label.setAttribute("y", 2.25 * SCALE);
      label.style.fontSize = 0.35 * SCALE + "rem";
    }
    label.setAttribute("fill", "white");
    label.style.fontWeight = "bolder";
    label.classList.add("Pin-Label");
  }

  circle.setAttribute("cx", 0);
  circle.setAttribute("cy", 0);
  if (listType !== "comments" && listType !== "diagnosis") {
    circle.setAttribute("r", 2.5 * SCALE);
  } else {
    circle.setAttribute("r", 5 * SCALE);
  }
  circle.setAttribute("fill", "red");
  circle.classList.add("Pin-Marker");
  g.appendChild(circle);
  g.appendChild(label);
  g.appendChild(desc);
  if (isSafari) {
    svg.setAttribute("x", svgP.x / zoom);
    svg.setAttribute("y", svgP.y / zoom);
  } else {
    svg.setAttribute("x", svgP.x);
    svg.setAttribute("y", svgP.y);
  }

  svg.setAttribute("id", droppedPin__id);
  svg.style.overflow = "visible";
  svg.classList.add(getClassForMarker(listType, subType));
  svg.classList.add("Pin-Point-Marker");
  svg.appendChild(g);
  rootSVG.appendChild(svg);
};

function AnatomyMapper({
  tool,
  // activePathId,
  // onHover,
  containerStyle,
  svgUrl,
  zoom,
}) {
  const [activePathId, setActivePathId] = useState(null);
  const [innerLayers, setInnerLayers] = useState([]);
  const [activeEGZ, setActiveEGZ] = useState(null);
  const [activeElement, setActiveElement] = useState(null);

  // const innerLayers = useRef([]);
  const list = useContext(List);

  const onMouseMove = (e) => {
    if (e.target.tagName !== "svg") {
      const pathId = e.target.getAttribute("id");
      if (pathId === "loaded-svg-cont") {
        return;
      }
      const x = e.clientX;
      const y = e.clientY;
      styleActivePath(pathId, list.hoverMode !== "standalone");
      const hierarchy =
        list.hoverMode === "standalone"
          ? []
          : getParentsAndChildren(pathId, { x, y });
      styleHierarchy(hierarchy);
      // console.log(e.target);
      setActiveElement(e.target);
      setActiveEGZ(getEGZInfo(pathId, { x, y }, { x, y }));
      setActivePathId(pathId);
      setInnerLayers(
        hierarchy.map((id) => ({
          id,
          egz: getEGZInfo(id, { x, y }, { x, y }),
        }))
      );
    }
  };

  const onMouseLeave = (e) => {
    setActivePathId(null);
    setInnerLayers([]);
    setActiveElement(null);
    // document.querySelector(".active-path-x")?.classList.remove("active-path-x");
    styleHierarchy([]);
    styleActivePath(null, false);
  };

  const onClick = (e) => {
    const pathId = e.target.getAttribute("id");

    const coords = {
      x: e.clientX,
      y: e.clientY,
    };
    if (
      pathId &&
      !pathId.includes("ordered__") &&
      !pathId.includes("loaded-svg-cont")
    ) {
      if (list.listType !== "comments" && list.listType !== "diagnosis") {
        const { droppedPin__id, id } = generateId(list.listType);
        addMarker(
          coords,
          list.listType === "ordered" ? list.orderedListContents.length : 0,
          zoom,
          droppedPin__id,
          list.listType,
          list.listType === "ordered" ? list.orderedType : list.unorderedType
        );

        const { names, ...attrInfo } = getEGZInfo(pathId, coords, {
          x: coords.x / zoom,
          y: coords.y / zoom,
        });

        list.updateList({
          id,
          pathId,
          content_id: pathId,
          droppedPin__id,
          hierarchy: getParentsAndChildren(pathId, coords),
          attrInfo,
          names,
        });
      } else if (
        list.listType === "comments" ||
        list.listType === "diagnosis"
      ) {
        const { droppedPin__id, id } = generateId(list.listType);
        const attrInfo = getEGZInfo(pathId, coords, {
          x: Math.round(coords.x / zoom),
          y: Math.round(coords.y / zoom),
        });

        addMarker(
          coords,
          list.listType === "comments"
            ? list.commentListContents.length
            : list.diagnosisListContents.length,
          zoom,
          droppedPin__id,
          list.listType,
          list.listType === "comments" ? list.commentsType : list.diagnosisType
        );
        const names = attrInfo.names;
        delete attrInfo.names;
        list.updateList({
          id,
          pathId: pathId ?? "white_space",
          content_id: pathId ?? "white_space",
          droppedPin__id,
          hierarchy: getParentsAndChildren(pathId),
          attrInfo,
          names,
        });
      }
    }
  };

  // const element = React.cloneElement(children,{onMouseMove:onMouseMove});
  return (
    <>
      <SVGHoverlay
        activePathId={activePathId}
        activeEGZ={activeEGZ}
        innerLayers={innerLayers}
        activeHoverElement={activeElement}
        hoverMode={list.hoverMode}
        sns={list.globalSNS}
      />
      <LoadSVG
        url={svgUrl}
        svgProps={{
          onMouseMove,
          onMouseLeave,
          onClick,
          style: { zIndex: tool === "default-tool" ? 5 : 0, ...containerStyle },
        }}
      />
    </>
  );
}

export default AnatomyMapper;

// rgba(255, 127, 0, 0.2);

// rgba(255, 255, 0, 0.2);
