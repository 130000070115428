import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => {
  return {
    nameBox: {
      position: "relative",
      "& > .edit-icon": {
        opacity: 0,
        position: "absolute",
        transition: "opacity .4s",
        "&  svg": {
          fontSize: 18,
        },
      },
      "&:hover > .edit-icon": {
        opacity: 1,
      },
    },
  };
});

export default function NameRenderer({
  noEdit = false,
  onClickEdit,
  sns,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <p
      style={{
        overflowWrap: "break-word",
        cursor: "pointer",
      }}
      className={classes.nameBox}
    >
      {sns.orderList.map(({ id, style, isArray, pre, post, visible }) => {
        const value = isArray ? restProps[id]?.join(" ").trim() : restProps[id];
        return (
          Boolean(value) &&
          visible && (
            <span style={style}>
              {pre}
              {value}
              {post}
            </span>
          )
        );
      })}
      {!noEdit && (
        <IconButton
          className={"edit-icon"}
          color={"primary"}
          size={"small"}
          style={{ display: "inline" }}
          onClick={onClickEdit}
        >
          <Edit />
        </IconButton>
      )}
    </p>
  );
}
